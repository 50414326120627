import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import { Toaster } from 'react-hot-toast';
import { UserProvider } from './utils/UserProvider';
import { PathProvider } from './utils/PathProvider';
import { Web3Provider } from './utils/Web3Provider';

import Home from './pages/Home';
import Help from './pages/Help';
import About from './pages/About';
import WorkWithUs from './pages/WorkWithUs';
import PageNotFound from './pages/PageNotFound';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';
import Confirmation from './pages/Confirmation';
import Dashboard from './pages/Dashboard/Dashboard';
import Verification from './pages/Verification/Verification';
import Users from './pages/Users/Users';
import ForgotPassword from './pages/ForgotPassword';
import User from './pages/Users/User';
import Transactions from './pages/Transactions/Transactions';
import Events from './pages/Events';
import Pools from './pages/Pools/Pools';
import Pool from './pages/Pools/Pool';
import Twilio2FA from './pages/Twilio2FA';
import Investments from './pages/Investments/Investments';
import { Convert } from './pages/Users/Convert';
import UserBalances from './pages/UserBalances/UserBalances';
import EditMIFID from './pages/EditMIFID/EditMIFID';
import DefiData from './pages/DefiData/DefiData';
import Roles from './pages/Roles/Roles';
import Advisory from './pages/AdvisoryMVP/Advisory'

const routes = {
    '/': { component: Home, },
    '/help': { component: Help },
    '/about': { component: About },
    '/work-with-us': { component: WorkWithUs },
    '/signup': { component: SignUp },
    '/signin': { component: SignIn },
    '/verify': { component: Twilio2FA },
    '/signout': { component: SignOut },
    '/confirm/:id': { component: Confirmation },
    '/forgotPassword': { component: ForgotPassword },
    '/dashboard': { component: Dashboard },
    '/verification': { component: Verification },
    '/users': { component: Users },
    '/users/:id': { component: User },
    '/transactions': { component: Transactions },
    '/events': { component: Events },
    '/editMIFID' : { component: EditMIFID},
    '/defi-data' : { component: DefiData },
    '/roles' : { component: Roles},
    '/advisory' : {component: Advisory},
    '/pools': { component: Pools },
    '/pools/create': { component: Pool },
    '/pools/:id': { component: Pool },
    '/investments': { component: Investments },
    '/userbalances': { component: UserBalances },
    '/users/:id/convert': { component: Convert },

    '*': { component: PageNotFound },
};

export default function AppRoot() {
    const location = useLocation();

    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 750,
            easing: 'ease-out-quart',
        });
    });

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'smooth';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = 'smooth';
        focusHandling('outline');
    }, [location.pathname]); // triggered on route change

    return (
        <PathProvider>
            <Web3Provider>
                <UserProvider>
                    <Switch>
                        {Object.keys(routes).map((path, key) => {
                            const route = routes[path];
                            return (
                                <Route key={key} path={path} exact={true} {...route} />
                            );
                        })}
                    </Switch>
                    <Toaster />
                </UserProvider>
            </Web3Provider>
        </PathProvider>
    );
}
