import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import Loading from '../../partials/Loading';
import LoggedPage from '../../partials/LoggedPage';
import Table from '../../partials/Table/Table';
import TableBody from '../../partials/Table/TableBody';
import TableCell from '../../partials/Table/TableCell';
import TableHead from '../../partials/Table/TableHead';
import TableRow from '../../partials/Table/TableRow';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { http } from '../../utils/utils';

const USERS_PER_PAGE = 5;

function Users() {
    const [page] = useState(0);
    const [newsletterPage] = useState(0);
    const [newsletterUsers, setNewsletterUsers] = useState([])
    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0)
    const history = useHistory();

    const goToUserPage = (userId) => {
        history.push(`/users/${userId}`);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const users = await http({ url: `/admin/users?skip=${page * USERS_PER_PAGE}&limit=${USERS_PER_PAGE}` });
            setUsers(users);
        };
        fetchUsers();
    }, [page]);

    useEffect(() => {
        const fetchNewsletterUsers = async () => {
            const newsletter = await http({ url: `/newsletter` });
            console.log(newsletter)
            setNewsletterUsers(newsletter.users);
            setCount(newsletter.count)
        };
        fetchNewsletterUsers();
    }, [newsletterPage]);

    const classTab = new Array("react-tabs__tab", "hover:bg-lightblue-400")

    return (
        <LoggedPage admin title="Users">
           
            <Tabs>
                <TabList>
                    <Tab className={classTab}><h1 className="text-grey-200">Users</h1></Tab>
                    <Tab className={classTab}><h1 className="text-grey-200">Newsletter</h1></Tab>
                </TabList>

            <TabPanel>
                <Loading if={!users?.length} className="pb-5">
                    <Table title="Users">
                        <TableHead>
                            <TableRow>
                                <TableCell header>Name</TableCell>
                                <TableCell header className="text-center w-24">Confirmed</TableCell>
                                <TableCell header className="text-center w-24">Admin</TableCell>
                                <TableCell header className="text-center w-24">Enabled</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => (
                                <TableRow key={user._id} className="hover:bg-lightblue-400 cursor-pointer" onClick={() => goToUserPage(user._id)}>
                                    <TableCell>
                                        <div>
                                            <div className="font-medium text-gray-200">{user.firstName} {user.lastName}</div>
                                            <div className="text-xs">{user.email}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <span className={!user.confirmed ? 'text-red-500' : ''}>{user.confirmed ? 'true' : 'false'}</span>
                                    </TableCell>
                                    <TableCell className="text-center">
                                        {user.isAdmin ? 'true' : 'false'}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <span className={!user.enabled ? 'text-red-500' : ''}>{user.enabled ? 'true' : 'false'}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Loading>
            </TabPanel>
            <TabPanel>
                <Loading if={!newsletterUsers?.length} className="pb-5">
                    <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">
                        <div class="md:col-span-6 lg:col-span-5">
                            <Table title={"Newsletter (" + count + ")"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell header>Email</TableCell>
                                        <TableCell header className="text-center w-24">Subscribed at</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newsletterUsers.map(n => (
                                        <TableRow key={n._id} className="hover:bg-lightblue-400 cursor-pointer">
                                            <TableCell>
                                                <div>
                                                    <div className="text-sm">{n.email}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <div>
                                                    <div className="text-sm">{new Date(n.createdAt).toLocaleString('it', { timeZone: 'Europe/Rome' })}</div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </Loading>
            </TabPanel>
        </Tabs>
        </LoggedPage>
    );
}

export default withRouter(Users);
