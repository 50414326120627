import React from 'react';

export default function LogoADAI(props) {
    return (
        <svg viewBox="0 0 256 256" width={props.width} height={props.height} className={props.className}>
            <defs>
                <linearGradient id="a" x1=".843" x2=".206" y1=".135" y2=".886" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#b6509e" />
                    <stop offset="1" stop-color="#2ebac6" />
                </linearGradient>
                <linearGradient id="b" x1=".5" x2=".5" y1="1.142" y2="-.105" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#f9a606" />
                    <stop offset="1" stop-color="#fbcc5f" />
                </linearGradient>
            </defs>
            <path fill="url(#a)" d="M128 256a128.976 128.976 0 01-25.8-2.6 127.309 127.309 0 01-45.77-19.261 128.366 128.366 0 01-46.375-56.315A127.357 127.357 0 012.6 153.8a129.251 129.251 0 010-51.593 127.31 127.31 0 0119.26-45.77 128.372 128.372 0 0156.317-46.378A127.33 127.33 0 01102.2 2.6a129.244 129.244 0 0151.593 0 127.308 127.308 0 0145.77 19.26 128.367 128.367 0 0146.375 56.316A127.343 127.343 0 01253.4 102.2a129.248 129.248 0 010 51.593 127.3 127.3 0 01-19.26 45.77 128.382 128.382 0 01-56.316 46.375A127.4 127.4 0 01153.8 253.4 128.977 128.977 0 01128 256zm0-242.287a115.145 115.145 0 00-23.033 2.322A113.657 113.657 0 0064.1 33.232a114.622 114.622 0 00-41.4 50.283 113.7 113.7 0 00-6.659 21.452 115.4 115.4 0 000 46.065 113.66 113.66 0 0017.2 40.866 114.627 114.627 0 0050.282 41.407 113.75 113.75 0 0021.453 6.658 115.381 115.381 0 0046.065 0 113.609 113.609 0 0040.866-17.2 114.622 114.622 0 0041.393-50.278 113.741 113.741 0 006.659-21.453 115.4 115.4 0 000-46.065 113.662 113.662 0 00-17.2-40.865A114.619 114.619 0 00172.485 22.7a113.74 113.74 0 00-21.453-6.659A115.145 115.145 0 00128 13.714z" />
            <g transform="translate(29.714 29.714)">
                <circle cx="98.285" cy="98.285" r="98.285" fill="url(#b)" />
                <path fill="#fff" d="M156.569 82.069h-11.664c-6.421-17.822-23.687-30.043-46.456-30.043H61.002v30.043H47.996v10.779h13.006v11.3H47.996v10.778h13.006v29.682h37.447c22.507 0 39.642-12.122 46.227-29.682h11.893V104.15h-9.273a48.891 48.891 0 00.36-5.832v-.262c0-1.769-.1-3.506-.262-5.209h9.206V82.069zM71.486 61.626h26.963c16.708 0 29.125 8.223 34.859 20.411H71.486V61.626zm26.963 73.288H71.486v-20.018h61.756c-5.766 11.991-18.146 20.017-34.793 20.017zm38.364-36.366a38.768 38.768 0 01-.393 5.57H71.486v-11.3h64.967a42.631 42.631 0 01.36 5.471z" />
            </g>
        </svg>
    );
}