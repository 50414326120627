const members = [
    { 
        pic: require('../images/vincenzo_profile.jpg'),
        name: 'VINCENZO MANZON',
        role: 'co-founder and CEO',
        description: 'DeFi expert and strategist with a legal background. CEO and Business Development and Clients Relations'
    },
    { 
        pic: require('../images/silvio_pic.jpg'),
        name: 'SILVIO BUSONERO',
        role: 'co-founder and Product and Operations',
        description: 'Data scientist and DeFi developer with a background in Traditional Finance. Head of product, operations and DeFi strategies'
    },
    { 
        pic: require('../images/andrea_pic.jpg'),
        name: 'Andrea Toscano',
        role: 'PhD, DeFi Strategist',
        description: 'DeFi early adopter and multichain expert. Analyst, Fund operations and DeFi strategies. Polygon community advocate. '
    },
    { 
        pic: require('../images/christian_pic.jpg'),
        name: 'CHRISTIAN KOBRIL',
        role: 'Full stack and blockchain Developer',
        description: 'Experienced full stack developer'
    },
    {
        pic: require('../images/leo_pic.jpg'),
        name: 'LEONARDO PELLICCIONE',
        role: 'Graphic Designer',
        description: 'UI / UX and graphics expert'
    },
    { 
        pic: require('../images/TeamMember.jpg'),
        name: 'PAOLO ANTONIO ROSSI',
        role: 'Technology Advisor',
        description: 'Experienced CTO with broad development and business development skills'
    },
    { 
        pic: require('../images/TeamMember.jpg'),
        name: 'JACOPO BELLINI',
        role: 'Strategy Advisor',
        description: 'Strategy and business development expert'
    }
]

export default members;