import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, withRouter } from 'react-router';
import LoggedPage from '../../partials/LoggedPage';
import { http } from '../../utils/utils';
import Portfolio from '../Dashboard/Portfolio';
import TransactionsTable from '../Transactions/TransactionTable';
import Loading from '../../partials/Loading';
import { UserContext } from '../../utils/UserProvider';
import InvestmentList from '../Investments/InvestmentList';
import ConversionList from '../Conversions/ConversionList';
import UserForm from './UserForm';
import UserForm2 from './UserForm2';
import MIFIDRecap from './MIFIDRecap';

function User() {
    const { id } = useParams();
    const [user, setUser] = useState();
    const [portfolio, setPortfolio] = useState();
    const history = useHistory();
    let mifidCheck = []
    if(user)
    {
        mifidCheck = user.verification.MIFID || [];
    }

    useEffect(() => {
        setUser();
        const fetchData = async () => {
            const user = await http({ url: `/admin/users/${id}` });
            setUser(user);
            const portfolio = await http({ url: `/admin/full-portfolio/${id}` });
            setPortfolio(portfolio);
        };
        fetchData();
    }, [id]);

    if (!user) {
        return (
            <LoggedPage admin>
                <div className="m-5">Loading...</div>
            </LoggedPage>
        );
    }
  
    const back = e => {
        e.preventDefault();
        history.push('/users');
    };

    return (
        <LoggedPage admin title={(
            <>
                <a onClick={back} className="block cursor-pointer mr-3" href="#0">
                    <svg height="24" width="24" viewBox="0 0 24 24" fill="currentcolor">
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                    </svg>
                </a>
                {user.firstName} {user.lastName}
                {mifidCheck.length === 0 
                    ? <><span className="text-red-600 pl-8 pr-1 text-md font-normal">&#9888;</span> <span className="text-sm font-normal">MIFID survey not complete</span> </>
                    : <><span className="text-green-600 pl-8 pr-1 text-md font-normal">&#10003;</span> <span className="text-sm font-normal">MIFID  completed</span> </>
                }
            </>
        )}>
            <div>
                <div className="grid grid-cols-1 md:grid-cols-6 gap-3 mb-5">
                    <div className="col-span-3">
                        <UserForm2 user={user} setUser={setUser} />
                    </div>
                    <div className="col-span-3">
                        <Loading if={!portfolio?.investment}>
                            <InvestmentList userData={portfolio?.investment} />
                        </Loading>
                         <Loading if={!portfolio?.deposit}>
                            <TransactionsTable
                                title="Deposits"
                                srcDstLabel="Source"
                                what="deposit"
                                userData={portfolio?.deposit}
                            />
                        </Loading>
                        <Loading if={!portfolio?.withdrawal}>
                            <TransactionsTable
                                title="Withdrawals"
                                srcDstLabel="Destination"
                                what="withdrawal"
                                userData={portfolio?.withdrawal}
                            />
                        </Loading>
                        <Loading if={!portfolio?.investment}>
                            <ConversionList userData={portfolio?.conversion} />
                        </Loading>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
                    <div className="col-span-4">
                        <MIFIDRecap user={user} setUser={setUser} />
                    </div>
                </div>
            

               
            </div>

        </LoggedPage>
    );
}

export default withRouter(User);