import Web3 from 'web3';
import { aaveContractData, 
         idleContractData, 
         curvea3CRVGaugeContractData, 
         curve3PoolContractData, 
         curveSteCRVGaugeContractData, 
         curveEthstTHPoolContract, 
         yearnUSDContractData,
         yearnDAIContractData
} from './assetList'
const axios = require('axios');

let web3;

if (window.web3) {
  web3 = new Web3(window.web3.currentProvider);
}


export const queryTokensBridgeVaults = async(vaultAddress) => {
    //by default, get requests are to be done as post with the graph
    try {    
            const result = await axios.post(
            'https://api.thegraph.com/subgraphs/name/enzymefinance/enzyme',
            {
            query:` 
            {
                fund(id : "${vaultAddress}") 
                {
                  name
                  accessor {
                    id
                  }
                  manager {
                    id
                  }
                  trackedAssets {
                    id
                    symbol
                    name
                    decimals
                    type
                    curvePoolAssetDetails {
                      id
                      lpToken {
                        id
                        name
                        decimals
                        type
                      }
                  
                    }
                  }
                }
            } ` 
            }
        );
    return(result.data.data)
    }catch(error) {
        console.error(error)
    }
}

export const getComptroller = async(poolAddress) => 
{
    return (await queryTokensBridgeVaults(poolAddress)).fund.accessor.id;
}

// Dynamic Order Plan
export const getDynamicOrderPlan = async(signerAddress, poolAddress) =>
{
    let minABI = [
        // balanceOf
        {
          "constant":true,
          "inputs":[{"name":"_owner","type":"address"}],
          "name":"balanceOf",
          "outputs":[{"name":"balance","type":"uint256"}],
          "type":"function"
        }
      ];

    const assetList = (await queryTokensBridgeVaults(poolAddress)).fund.trackedAssets;

    const orderPlan = [];
    let orderRow = {};

    for(const [index, token] of Object.entries( assetList ))
    {
        if(token.name != 'USD Coin')
        {
        orderRow = await getDynamicOrderPlanRow(minABI, token, signerAddress);
        orderPlan.push(orderRow);
        }   

        if(token.curvePoolAssetDetails !== null)
        {
            orderRow = await getDynamicOrderPlanRow(minABI, token.curvePoolAssetDetails.lpToken, signerAddress);
            orderPlan.push(orderRow);

        }

    }
    return orderPlan;
}


const getDynamicOrderPlanRow = async(ABI, token, signerAddress) => {
  const tokenContract = await new web3.eth.Contract(ABI, token.id);
  const balance = await tokenContract.methods.balanceOf(signerAddress).call();
  let transaction = "";
  let to = "USD Coin";

  if(token.type == "ETH")
  {
    transaction = "paraswap";
  }

  if((token.name.toLowerCase()).includes("idle"))
  {
    transaction = 'idle';
  }
  else if((token.name.toLowerCase()).includes("aave"))
  {
    transaction = 'aave';
  }
  else if((token.name.toLowerCase()).includes("yvault")) {
    if((token.name.toLowerCase()).includes('dai')) {
      transaction = 'yvDAI';
    } else if((token.name.toLowerCase()).includes('usdc')) {
      transaction = 'yvUSDC';
    }
  }
  else if((token.name.toLowerCase()).includes("curve"))
  {
    if((token.name.toLowerCase()).includes("gauge"))
    {
        to = token.curvePoolAssetDetails.lpToken.name;

        if((token.name.toLowerCase()).includes("a3crv")) {
            transaction = 'a3CRVgauge';
        }
        else if((token.name.toLowerCase()).includes("stecrv")) 
        {
            transaction = 'steCRVgauge';
        } 
    }
    else
    {
        if((token.name.toLowerCase()).includes("adai/ausdc/ausdt")) {
            transaction = 'a3CRV';
        }
        else if((token.name.toLowerCase()).includes("eth/steth")) 
        {
            transaction = 'steCRV';
        }
    }
  }

  return {"from" : token.name, "fromDecimals" : token.decimals, "balance" : (balance / 10**token.decimals), "to" : to, "transaction" : transaction};
}
//END DYNAMIC ORDER PLAN


export const queryTokensTheGraph = async(poolAddress) =>
{
    const assetList = (await queryTokensBridgeVaults(poolAddress)).fund.trackedAssets;

    const tokenList = [];
    
    for(const [index, token] of Object.entries( assetList ))
    {
        if(token.type == "ETH")
        { 
           tokenList.push({"decimals" : token.decimals, "symbol" : token.symbol, "name" : token.name, "address" : token.id })
        }
    }
    return tokenList;
}

export const withdrawAave = async (signerAddress, Token, amount) => 
{
	//Contract Instance
	const aaveLendingContract = await new web3.eth.Contract(aaveContractData.ABI, aaveContractData.address);

	//Withdraw Aave...
	
	const tx = await aaveLendingContract.methods.withdraw(Token[Object.keys(Token)[0]].address, amount, signerAddress).send({ from: signerAddress });


}

export const withdrawIdle = async (signerAddress, amount) => 
{	
	const idleContract = new web3.eth.Contract(idleContractData.ABI, idleContractData.address);
	
	//Redeem Idle...
	const tx = await idleContract.methods.redeemIdleToken(amount).send({ from: signerAddress });

}

export const withdrawCurvea3CRVgauge = async (signerAddress, amount) => 
{
	// Guauge Contract instance
	const Curvea3CRVContract = new web3.eth.Contract(curvea3CRVGaugeContractData.ABI, curvea3CRVGaugeContractData.address);

	//Withdraw CRV token and reward...
	const tx = await Curvea3CRVContract.methods.withdraw(amount).send({ from: signerAddress });
	
}

export const withdrawCurvea3CRV = async (signerAddress, amount) => 
{
	//Pool Contract instance
	const curvePoolContract = new web3.eth.Contract(curve3PoolContractData.ABI, curve3PoolContractData.address);
	
	//Convert CRV token...
	const tx2 = await curvePoolContract.methods.remove_liquidity_one_coin(amount,1,5,true).send({ from: signerAddress });

	//TODO: Unstake Staked Aave
}

export const withdrawYearnUSDC = async (signerAddress, amount) =>
{
	//Whitdraw yvUSDC
	const yearnUSDCContract = new web3.eth.Contract(yearnUSDContractData.ABI, yearnUSDContractData.address);
	const tx = await yearnUSDCContract.methods.withdraw(amount).send({ from: signerAddress });
}

export const withdrawYearnDAI = async (signerAddress, amount) =>
{
	//Withdraw yvDAI
	const yearnDAIContract = new web3.eth.Contract(yearnDAIContractData.ABI, yearnDAIContractData.address);
	const tx = await yearnDAIContract.methods.withdraw(amount).send({ from: signerAddress });
}

export const withdrawCurveSteCRVgauge = async (signerAddress, amount) => 
{ 
    const CurveaSteCRVContract = new web3.eth.Contract(curveSteCRVGaugeContractData.ABI, curveSteCRVGaugeContractData.address);

    //Withdraw CRV token and reward...
    const tx = await CurveaSteCRVContract.methods.withdraw(amount).send({ from: signerAddress });;

}
export const withdrawCurveSteCRV = async (signerAddress, amount) => 
{
    const CurveETHPoolContract = new web3.eth.Contract(curveEthstTHPoolContract.ABI, curveEthstTHPoolContract.address);

    //Convert CRV token...
    const tx2 = await CurveETHPoolContract.methods.remove_liquidity_one_coin(amount,0,5).send({ from: signerAddress });;

    //TODO: Lido DAO --> USDC
}