import React from 'react';
import TeamCarousel from './TeamCarousel'
import members from './Members'

function Team() {

  const membersList = members.map((m) => {
    return (
      <div className="ml-5 mr-5">
        <div className="flip-card">
          <div className="flip-card-inner">
            <div class="w-full rounded flip-card-front">
              <img src={m.pic} alt="image"/>
            </div>
            <div class="flip-card-back p-5">
              <p>{m.description}</p> 
            </div>
          </div>
        </div>
        <p className="mt-3 mweb-2 text-dark-blue-bridge uppercase font-bold">{m.name}</p>
        <p className="mb-2 text-blue-bridge">{m.role}</p>
      </div>
    );
  });

  return (
    <section className="feature-section-bg z-0 relative">


      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="text-black max-w-2xl mx-auto text-center pb-12 md:pb-12" data-aos-id-cards1>
            <h4 className="h4 mb-4 text-dark-blue-bridge">BRIDGE Team</h4>
          </div>

          {/* Items */}
          <div class="md:grid lg:grid grid-cols-3 gap-4 hidden">
            {membersList}
          </div>
          <div className="md:hidden lg:hidden">
            <TeamCarousel/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
