import React from 'react';

import Header from '../partials/Header/Header';
import Team from '../partials/Team';
import HeroAbout from './HeroAbout';
import Footer from '../partials/Footer';

function About() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden work-sans">

      {/*  Site header */}
      <Header />
      {/*  Page sections */}
      <HeroAbout />
      <Team />
      <section className="more-mb z-0 relative work-sans">
        <div className="max-w-6xl mx-auto md:px-6 more-mb">
          <div className="pb-10 md:pt-20 md:pb-16">

            {/* Hero content */}
            <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

              {/* Content */}
              <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>
              <div className="md:col-span-8 lg:col-span-8 mb-8 md:mb-0 text-center md:text-left max-w-6xl">
                <h2 className="h2 text-3xl mb-4 font-bold text-center" data-aos="fade-down">
                  Strategic Partners
                </h2>
                <div className="relative md:flex lg:flex md:flex-row lg:flew-row flex-column justify-center gap-12 mt-10 hidden">
                  <div className="lg:w-2/3 md:w-2/3 text-left pt-05">
                    <img className="h-14 md:inline lg:inline" src={require('../images/Fintech-District-logo 2.png')} alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
                  </div>
                </div>
              </div>
              <div class="flex flex-col md:hidden lg:hidden">
                  <div className="">
                    <img className="h-8 block m-auto" src={require('../images/Fintech-District-logo 2.png')} alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  
      
      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default About;