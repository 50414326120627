import React from 'react';

export default function Logo({ className } = { className: '' }) {
    return (
        <svg viewBox="0 0 79.481 10.383" className={className}>
            <g transform="translate(-83.48 -12.951)">
                <path fill="#505e82" d="m96.294 14.554h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m99.115 14.554h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m100.49 14.554h1.4499v-1.4499h-1.4499z"/>
                <path fill="#ffffff" d="m104.76 14.554h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m96.294 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m99.115 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m101.94 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m104.76 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m104.76 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m99.115 20.306h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m101.94 20.306h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m104.76 20.306h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m101.94 23.182h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m104.76 23.182h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m93.394 14.554v-1.4499h-9.9141v10.078h2.6162s0.06315-2.4137 1.3956-3.8248c1.2072-1.2788 3.1933-1.9276 5.9023-1.9276v-1.4499c-3.1383 0-5.4857 0.80892-6.9755 2.4042-0.90699 0.97084-1.3099 2.0443-1.4866 2.8688v-6.6989z"/>
                <path fill="#ffffff" d="m112.85 21.044c-0.23459 0.19332-0.59055 0.29034-1.0689 0.29034h-2.1534v-2.2296h2.1534c0.47836 0 0.83432 0.09454 1.0689 0.28258 0.2339 0.18838 0.35102 0.46073 0.35102 0.81703 0 0.36654-0.11712 0.64664-0.35102 0.83961m-3.2223-6.0159h1.9089c0.93663 0 1.4048 0.36618 1.4048 1.0993 0 0.73307-0.46814 1.0996-1.4048 1.0996h-1.9089zm3.955 3.0688v-0.01482c0.69215-0.11218 1.2065-0.37465 1.5423-0.78669 0.33584-0.4124 0.50377-0.90346 0.50377-1.4736 0-0.87524-0.29528-1.5445-0.88583-2.008-0.59055-0.46284-1.5064-0.69991-2.7481-0.70979h-4.9015v10.078h5.1304c1.2213 0 2.1378-0.23636 2.7488-0.71014 0.61066-0.47308 0.91581-1.1423 0.91581-2.0077 0-0.61066-0.1912-1.1324-0.57256-1.5653-0.3817-0.43286-0.95955-0.69956-1.7332-0.80186"/>
                <path fill="#ffffff" d="m120.11 15.089h1.8627c0.50941 0 0.89076 0.10195 1.1455 0.30515 0.25435 0.20391 0.38171 0.52458 0.38171 0.96202 0 0.44838-0.12736 0.774-0.38171 0.97755-0.25471 0.20355-0.63606 0.30515-1.1455 0.30515h-1.8627zm5.0391 3.466c0.66146-0.5394 0.99272-1.3229 0.99272-2.3513 0-0.93662-0.33373-1.6877-1.0001-2.2525-0.6671-0.5648-1.6009-0.84737-2.8025-0.84737h-4.8098v10.078h2.5806v-3.6798h1.5741l2.1519 3.6798h2.9009l-2.6275-4.0721c0.39828-0.133 0.74683-0.31574 1.0396-0.55457"/>
                <path fill="#ffffff" d="m128.05 23.182h2.6264v-10.078h-2.6264z"/>
                <path fill="#ffffff" d="m138.8 20.426c-0.39194 0.51435-1.0156 0.77117-1.8708 0.77117h-1.3896v-6.108h1.3896c0.85513 0 1.4788 0.25718 1.8708 0.77117 0.39158 0.514 0.58773 1.2749 0.58773 2.2828s-0.19615 1.7688-0.58773 2.2828m-1.6108-7.3219h-4.276v10.078h4.276c1.5776 0 2.7862-0.43709 3.6266-1.313 0.83961-0.8756 1.2594-2.117 1.2594-3.7257 0-1.6083-0.41981-2.8504-1.2594-3.726-0.84031-0.87524-2.0489-1.313-3.6266-1.313"/>
                <path fill="#ffffff" d="m148.36 19.502h2.3058v0.1524c0 0.2854-0.0508 0.53234-0.1524 0.74083-0.10231 0.20884-0.24448 0.38171-0.42757 0.51894-0.18344 0.13758-0.39687 0.24201-0.64135 0.31327-0.24412 0.07126-0.51435 0.10689-0.80927 0.10689-0.54963 0-1.0104-0.11465-1.3818-0.3436-0.37183-0.22931-0.65158-0.58279-0.84032-1.0612-0.18838-0.47837-0.28222-1.0742-0.28222-1.7868 0-0.68192 0.0917-1.2545 0.27481-1.7177 0.1831-0.4632 0.45297-0.81421 0.80963-1.0534 0.35595-0.23918 0.78352-0.35913 1.2827-0.35913 0.49847 0 0.91581 0.11712 1.2516 0.35137 0.3362 0.23424 0.57045 0.61066 0.70238 1.1296l2.4282-0.96167c-0.38699-0.8957-0.93168-1.5498-1.6337-1.9622-0.70273-0.4124-1.6238-0.61842-2.764-0.61842-1.028 0-1.9219 0.20849-2.6801 0.62618-0.75812 0.41734-1.3413 1.0128-1.748 1.7865-0.40711 0.77364-0.61101 1.7053-0.61101 2.7944 0 1.1095 0.19297 2.0514 0.58032 2.8247 0.38664 0.77399 0.92886 1.3593 1.626 1.7561 0.69744 0.39688 1.494 0.59549 2.3897 0.59549 0.87559 0 1.5907-0.18556 2.1456-0.55739 0.33867-0.22648 0.60572-0.52035 0.81739-0.86642l0.10654 1.2711h1.7865v-5.436h-4.5353z"/>
                <path fill="#ffffff" d="m157.54 21.151v-2.0613h4.1532v-1.9089h-4.1532v-2.0461h5.2225v-2.0309h-7.849v10.078h8.0472v-2.0309z"/>
            </g>
        </svg>
    );
}