import moment from 'moment';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import emptyProfilePic from '../../images/blank-profile-picture.png';
import emptyDoc from '../../images/blank-document.png';
import { http } from '../../utils/utils';
import SelectRole from './SelectRole'

export default function UserForm2(props) {

    const { user, setUser } = props;
    const [toggleAdminDisabled, setToggleAdminDisabled] = useState(false);
    const [verifyDisabled, setVerifyDisabled] = useState(false);
    const [profilePicVisible, setProfilePicVisible] = useState(false);
    const [documentFrontVisible, setDocumentFrontVisible] = useState(false);
    const [documentRetroVisible, setDocumentRetroVisible] = useState(false);
    const [utilitiesBillVisible, setUtilitiesBillVisible] = useState(false);
    const [roles, setRoles] = useState([]);

    const toggleAdmin = async () => {
        setToggleAdminDisabled(true);
        try {
            const newUserData = await http({
                method: 'PATCH',
                url: `/admin/make-admin/${user._id}`,
                form: {
                    isAdmin: !user.isAdmin
                }
            });
            setUser({ ...user, ...newUserData });
        } catch (e) {
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
        setToggleAdminDisabled(false);
    };

    useEffect(() => {
        if (roles.length == 0) getAdminRoles();
    }, [])

    const verify = async () => {
        setVerifyDisabled(true);
        try {
            const newUserData = await http({
                method: 'PATCH',
                url: `/admin/enable-user/${user._id}`,
                form: {
                    enabled: !user.enabled
                }
            });
            setUser({ ...user, ...newUserData });
        } catch (e) {
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
        setVerifyDisabled(false);
    };

    const confirmEmail = async () => {
        try {
            const newUserData = await http({
                method: 'POST',
                url: `/users/confirm/${user._id}`,
            });
            setUser({ ...user, ...newUserData });
        } catch (e) {
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
    };

    const { personalInfo, residenceAddress, settlementAddress, document, utilitiesBill } = {
        personalInfo: {},
        residenceAddress: {},
        settlementAddress: {},
        document: {},
        utilitiesBill: {},
        ...(user.verification || {})
    };

    const kycFilled = user.verification
        && user.verification.personalInfo
        && user.verification.residenceAddress
        && user.verification.settlementAddress
        && user.verification.document
        && user.verification.document.documentType
        && user.verification.document.front
        && user.verification.document.retro
        && user.verification.document.profilePic;
    
    const getAdminRoles = async () => {
        try {
            const roles = await http({ url: `/role` });
            setRoles(roles.role)
        }
        catch(e) {
            //handle if no role has been created yet
            console.log("Error " + e.message.toString());           
        }
    }

    const createRoleSelect = () => {

        const select = (
            <>
            <SelectRole roleId={user.roleId} userId={user._id} roles={roles}></SelectRole>  
            </>
        )

        return select;
    }

    return (
        <>
        <div className="grid grid-cols-3 gap-2 mb-2">
                <div className="rounded border border-opacity-0">
                     {document.profilePic ? <img src={`data:image;base64,${document.profilePic}`} alt="Profile picture" /> : <img src={emptyProfilePic} alt="Profile picture" />}
                </div>
            
            <div className="shadow-lg col-span-2 rounded border border-white text-gray-600 p-3">
               <div className="grid grid-cols-2 gap-4">
                <div>
                    <div className="block border-b border-white border-opacity-50">
                        <label className="text-white uppercase font-bold text-sm">Admin rights</label>
                        <span
                        className={`cursor-pointer text-xs text-right text-lightblue-100 hover:text-lightblue-200 font-bold ml-4 ${toggleAdminDisabled && 'opacity-50'}`}
                        onClick={() => !toggleAdminDisabled && toggleAdmin()}
                        >
                        {user.isAdmin ? 'Remove' : 'Grant'}
                        </span>
                    </div>
                    <span className={!user.isAdmin ? 'text-red-500 uppercase font-bold' : 'text-green-500 uppercase font-bold'}>{user.isAdmin ? 'true' : 'false'}</span>
                </div>
                <div>
                    <div className="block border-b border-white border-opacity-50">
                        <label className="text-white uppercase font-bold text-sm">KYC</label>
                    </div>
                    <span className={!kycFilled ? 'text-red-500 uppercase font-bold' : 'text-green-500 uppercase font-bold'}>{kycFilled ? 'true' : 'false'}</span>
                </div>

                <div>
                    <div className="block border-b border-white border-opacity-50">
                        <label className="text-white uppercase font-bold  text-sm">Enabled</label>
                        <span
                        className={`cursor-pointer text-xs text-right text-lightblue-100 hover:text-lightblue-200 font-bold ml-4 ${verifyDisabled && 'opacity-50'}`}
                        onClick={() => !verifyDisabled && verify()}
                        >
                        {user.enabled ? 'Disable' : 'Enable'}
                        </span>
                    </div>
                    <span className={!user.enabled ? 'text-red-500 uppercase font-bold' : 'text-green-500 uppercase font-bold'}>{user.enabled ? 'true' : 'false'}</span>
                </div>
                <div>
                    {createRoleSelect()} 
                </div>
                </div>
            </div>
        </div>    
        <div className="shadow-lg col-span-3 rounded border border-white text-gray-600 text-xs font-bold p-5">
        <div className="grid grid-cols-3 gap-4">   
            <div>
                <label className="block text-white uppercase font-bold  text-sm">Email</label>
                {user.email || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold  text-sm">Phone number</label>
                {user.phone || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold  text-sm">Purpose of use</label>
                {personalInfo.purposeOfUse || '-'}
            </div>

            <div>
                <label className="block text-white uppercase font-bold  text-sm">Birth date</label>
                {personalInfo.birthday ? moment(personalInfo.birthday).format('LL') : '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold text-sm">Birth place</label>
                {personalInfo.townOfBirth || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold text-sm">Professional investor</label>
                {'-'|| '-'}
            </div>

            <div>
                <label className="block text-white uppercase font-bold text-sm">Country</label>
                {residenceAddress.country || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold text-sm">Gender</label>
                {personalInfo.gender || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold text-sm">Company</label>
                {'-'|| '-'}
            </div>

            <div>
                <label className="block text-white uppercase font-bold text-sm">Settlement address</label>
                {[settlementAddress.street, settlementAddress.houseNumber].filter(v => v).join(',') || '-'}<br />
                {[settlementAddress.postcode, settlementAddress.country].filter(v => v).join(' ') || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold text-sm">Residence address</label>
                {[residenceAddress.street, residenceAddress.houseNumber].filter(v => v).join(',') || '-'}<br />
                {[residenceAddress.postcode, residenceAddress.country].filter(v => v).join(' ') || '-'}
            </div>
            <div>
                <label className="block text-white uppercase font-bold text-sm">Document type</label>
                {document.documentType || '-'}
            </div>

            <div className="col-span-3 border-t border-white border-opacity-50 p-4">
                <div className="grid grid-cols-3 gap-4">   
                    <div>
                        <label className="block text-white uppercase font-bold text-sm">Document front</label>
                        {!documentFrontVisible
                            ? (
                                <div className="cursor-pointer text-lightblue-100" onClick={() => setDocumentFrontVisible(!documentFrontVisible)}>
                                    Show
                                </div>
                            )
                            : (
                              
                                <div className="cursor-pointer text-lightblue-100" onClick={() => setDocumentFrontVisible(!documentFrontVisible)}>
                                    Hide
                                </div>

                            )
                        }
                    </div>
                    <div>
                        <label className="block text-white uppercase font-bold text-sm">Document Retro</label>
                        {!documentRetroVisible
                            ? (
                                <div className="cursor-pointer text-lightblue-100" onClick={() => setDocumentRetroVisible(!documentRetroVisible)}>
                                    Show
                                </div>
                            )
                            : (
                              
                                <div className="cursor-pointer text-lightblue-100" onClick={() => setDocumentRetroVisible(!documentRetroVisible)}>
                                    Hide
                                </div>

                            )
                        }
                    </div>
                    <div>
                        <label className="block text-white uppercase font-bold text-sm">Utilities bill</label>
                        {!utilitiesBillVisible
                            ? (
                                <div className="cursor-pointer text-lightblue-100" onClick={() => setUtilitiesBillVisible(!utilitiesBillVisible)}>
                                    Show
                                </div>
                            )
                            : (
                                <div className="cursor-pointer text-lightblue-100" onClick={() => setUtilitiesBillVisible(!utilitiesBillVisible)}>
                                    Hide
                                </div>
                              
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="col-span-3 border-t border-white border-opacity-50 p-4">
                {(utilitiesBillVisible && utilitiesBill) ? <img class="object-contain h-48 w-full mb-2" src={`data:image;base64,${utilitiesBill}`} alt="utilities bill" /> : ''}
                {(documentRetroVisible &&  document.retro) ? <img class="object-contain h-48 w-full mb-2" src={`data:image;base64,${document.retro}`} alt="Document back" /> : ''}
                {(documentFrontVisible && document.front) ? <img class="object-contain h-48 w-full mb-2" src={`data:image;base64,${document.front}`} alt="Document front" /> : ''}   
                {(!documentFrontVisible && !documentRetroVisible && !utilitiesBillVisible) ? <img class="object-contain h-48 w-full" src={emptyDoc} alt="Document front" /> : ''}   
            
            </div>

        </div>
        </div>


        </>
    );
}