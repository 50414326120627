import React from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';


const WRONG_NETWORK = 'You should connect to the Ethereum Mainnet';
const WRONG_ACCOUNT = 'You don\'t have any account on the Ethereum Mainnet';
const SUCCSESS_CONNECTED = 'Successfully connected to the Ethereum Mainnet';
const METAMASK_UNINSTALLED = "Non-Ethereum browser detected. You should consider trying MetaMask!";
//const CHAIN_ID = (process.env.REACT_APP_CHAINID).toString(); // Hardhat
// const CHAIN_ID = '0x2a'; kovan
// const CHAIN_ID = '0x1'; MAINNET
// const CHAIN_ID = '0x7a69'; HARDHAT
 const CHAIN_ID = process.env.REACT_APP_CHAINID;


export const Web3Context = React.createContext();

export class Web3Provider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connectionStatus: null,
            notifyLabel: '',
            balance: -1,
            address: '',
            shown: null,
            walletInstalledStatus: true,
        };

    }

    async componentDidMount() {

    }

    detectProvider = async() => {
        const provider = await detectEthereumProvider();
        // if (provider) {
        //   // From now on, this should always be true:
        //   // provider === window.ethereum
        //   startApp(provider); // initialize your app
        // } else {
        //   console.log('Please install MetaMask!');
        // }
    }

    loadWeb3 = async () => {
        let self = this;
        self.detectProvider()
        if (window.ethereum) {
            // register handlers
            window.ethereum.on("chainChanged", function (chain) {
                if (chain !== CHAIN_ID) {
                    self.setState({ ...self.state, connectionStatus: false, notifyLabel: WRONG_NETWORK });
                } else {
                    self.getBlockchainData();
                }

            });
            window.ethereum.on("accountsChanged", function (accounts) {
                if (accounts === undefined || accounts[0] === undefined) {
                    self.setState({ ...self.state, connectionStatus: false, notifyLabel: WRONG_ACCOUNT });
                }
                if (window.ethereum.chainId !== CHAIN_ID) {
                    self.setState({ ...self.state, connectionStatus: false, notifyLabel: WRONG_NETWORK });
                } else {
                    self.getBlockchainData();
                }
            });
            window.ethereum.on("disconnect", function () { });
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            if (window.ethereum.chainId !== CHAIN_ID) {
                self.setState({ ...self.state, connectionStatus: false, notifyLabel: WRONG_NETWORK });
            } else {
                self.getBlockchainData();
            }

        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
            self.getBlockchainData();
            self.setState({ ...self.state, connectionStatus: true, shown: false });
        } else {
            self.setState({ ...self.state, walletInstalledStatus: false, connectionStatus: false, notifyLabel: METAMASK_UNINSTALLED });
        }
    }

    getBlockchainData = async () => {
        let accounts = await window.web3.eth.getAccounts();
        window.web3.eth.getBalance(accounts[0]).then((data) => {
            let balance = Math.floor(data / 10000000000 + 0.5) / 100000000;
            this.setState({ ...this.state, connectionStatus: true, shown: true, notifyLabel: SUCCSESS_CONNECTED, balance: balance, address: accounts[0] });
        });
    }

    setShown = async () => {
        //this.setState({ ...this.state, shown: true })
    }

    render() {
        return (
            <Web3Context.Provider
                value={{
                    connectionStatus: this.state.connectionStatus,
                    notifyLabel: this.state.notifyLabel,
                    balance: this.state.balance,
                    address: this.state.address,
                    walletInstalledStatus: this.state.walletInstalledStatus,
                    loadWeb3: this.loadWeb3,
                    shown: this.shown,
                    setShown: this.setShown
                }}
            >
                {this.props.children}
            </Web3Context.Provider>
        );
    }
}
