import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../../partials/Loading';
import CryptoCoinIcon from './CryptoCoinIcon'
import TransactionButton from './transactionButton'
import { getDynamicOrderPlan } from './blockchainFunctions';
import { pools } from './assetList';
import arrow from '../../../images/arrow.png';


export default function OrderTable(props) {

    const [orderPlan, setOrderPlan] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const {poolName , address} = props;

    const balances = async () => 
    {
        setIsLoading(true);
        setOrderPlan(await getDynamicOrderPlan(address, pools[poolName]));
        setIsLoading(false);
    }

    useEffect(() => {
        if (orderPlan.length == 0) { balances(); }
    }, [orderPlan])


    const listOfTx = orderPlan.map(tx => 
            <>
            <tr>
            {tx.balance.toFixed(2) >= 0.01 
                ? <td>{tx.balance.toFixed(2)}</td> 
                : tx.balance < 1e-12
                    ? <td> {'0'} </td>
                    : <td> {'<0.01'} </td>
            }
            <CryptoCoinIcon name={tx.from}/>
            <td><img src={arrow} height="50" width="50"/></td>
            <CryptoCoinIcon name={tx.to}/>
            <TransactionButton balances={balances} fromDecimals={tx.fromDecimals} fromName={tx.from} toName={tx.to} transaction={tx.transaction} amount={tx.balance} address={address} poolName={poolName} disabled={(tx.balance <= 1e-12)}/>
            </tr>         
            </>
        );

    return (
         <Loading if={isLoading}>
         <table class=" table-auto p-7">
                  <thead className="mb-4">
                    <tr>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">FROM</th>
                      <th scope="col"></th>
                      <th scope="col">TO</th>
                      <th scope="col">ACTION</th>
                      <th scope="col">FILLED</th>
                    </tr>
                  </thead>
                  <tbody className="order-tbody">
                    {listOfTx}
                  </tbody>
                </table>
            </Loading>
    );
}