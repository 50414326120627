import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Header from '../partials/Header/Header';
import Body from '../partials/Body';
import { FormInput } from '../utils/FormInput';
import { http } from '../utils/utils';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { UserContext } from '../utils/UserProvider';

function Twilio2FA(props) {
    const form = useForm();
    const { formState, errors, handleSubmit } = form;
    const history = useHistory();
    const [isSent, setSent] = useState(false)
    const userContext = useContext(UserContext);
    const phone = props.location.state.phone;

    var cryptedPhone = phone.slice(0, 3) + '-' + 'XXX-XXX-XX' + phone.slice(phone.length - 2, phone.length);

    const onSubmit = async ({ code }) => {
        try {

            await userContext.verify(code, phone);
            history.push('/pools');
        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || 'Code is not correct');
        }
    };

    

    const sendMessage = async () => {
        if(!isSent) {
            const res = await http({
                method: 'POST',
                url: '/twilio',
                form: {
                    phone
                }
            });

            setSent(true);

        }
    }

    useEffect(() => {
        if (!isSent) { sendMessage(); setSent(true)  }
    })

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <Body className="flex-grow">
                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                            <div className="max-w-xl mx-auto">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <fieldset disabled={formState.isSubmitting}>
                                        <div className="max-w-3xl mx-auto pb-2 md:pb-2">
                                            <h1 className="h3 mb-6">
                                                Enter your verification code
                                            </h1>
                                            <p className="mb-1">Input the code we sent to {cryptedPhone} to access your account</p>
                                        </div>

                                        <div className="flex flex-row">
                                            <div className="w-8/12">
                                                <FormInput
                                                    name="code"
                                                    form={form}
                                                    errors={errors}
                                                    validation={{
                                                        required: true,
                                                    }}
                                                    render={({ name, className, ref, label }) => (
                                                        <input
                                                            ref={ref}
                                                            name={name}
                                                            className={className}
                                                            placeholder='Code'
                                                            type="code"
                                                            autoFocus
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="3/12 items-center">
                                                <button type="button" 
                                                        className="btn p-0 mt-3 ml-3 text-lightblue hover:text-white"
                                                        onClick={() => setSent(false)}
                                                >
                                                Resend code
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap mt-6">
                                            <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-100 w-full">
                                                Verify
                                            </button>
                                        </div>
                                    </fieldset>
                                </form>

                                <div className="text-gray-400 text-center mt-6">
                                    Bridge defi is currently available for beta testers only &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Body>
        </div>
    );
}

export default withRouter(Twilio2FA);
