import React from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../utils/FormInput';
import toast from 'react-hot-toast';
import { http } from '../../utils/utils';


export default function RoleForm(props) {

    const form = useForm({ defaultValues: { active: true } });
    const { formState, handleSubmit, errors, getValues, reset, watch } = form;

    const modifyRole = async (formData) => {

        const roleData = {
           permissions: formData.permissions
        }

        try {

            await http({
                method: 'PUT',
                url: `/role/${props.role._id}`,
                form: roleData
            });
        
        toast.success('Role successfully updated');
        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || `${JSON.stringify(e.reason.message)}`);
        }
    };

    const generatePermissionsCheckbox = (permissions, index) => {
        const permissionTable = permissions.map((r => {
            const operations = Object.entries(r[1])
            return (
                <>
                    <div className="w-2/12">
                        <h3 className="mt-2 uppercase">{r[0]}</h3>
                            {operations.map(o => {
                                return (
                                    <>
                                    <FormInput
                                                className="form-control mt-2"
                                                name={`permissions.${r[0]}.${o[0]}`}
                                                errors={errors}
                                                form={form}
                                                render={({ name, ref }) => (
                                                    <label className="flex items-center">
                                                        <input ref={ref} name={name} className="form-checkbox" 
                                                                         type="checkbox" defaultChecked={o[1]}/>
                                                        <span className="ml-3">
                                                            {o[0]}
                                                        </span>
                                                    </label>
                                                )}
                                            />
                                    </>
                                )
                            })}
                    </div>
                </>
            );
        }))

        return permissionTable;
    }

    let permissionsCheckbox = generatePermissionsCheckbox(Object.entries(props.role.permissions), props.index)

    return (
        <>
        <h4 className="h4 mb-2">{props.role.name}</h4>
                <div className="mt-1 mb-4 bg-darkblue-200 text-gray-200 shadow-lg rounded-sm overflow-hidden p-4 pl-4 pb-4">
                    <form onSubmit={handleSubmit(modifyRole)}>
                        <fieldset disabled={formState.isSubmitting}>

                            <div className="flex flex-row">
                                {permissionsCheckbox}
                            </div>
                            <div className="w-full">
                                <button
                                    type="submit"
                                    className="btn btn-sm bg-lightblue-200 px-3 py-1 text-white"
                                >
                                    Modify
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
        </>
    )
}