const staticPermissions = [
    [
        "admin",
        {
            "create": false,
        }
    ],
    [
        "pool",
        {
            "create": false,
            "update": false,
        }
    ],
    [
        "portfolio",
        {
            "read": false,
            "convert": false
        }
    ],
    [
        "investment",
        {
            "read": false,
            "reject": false,
            "approve": false
        }
    ],
    [
        "mifid",
        {
            "create": false
        }
    ],
    [
        "user",
        {
            "read": false,
            "update": false,
            "enable": false
        }
    ],
    [
        "role",
        {
            "create": false,
            "read": false,
            "update": false
        }
    ],
    [
        "withdrawal",
        {
            "read": false,
            "reject": false,
            "approve": false
        }
    ],
    [
        "deposit",
        {
            "read": false,
            "approve": false,
            "reject": false
        }
    ],
    [
        "event",
        {
            "read": false,
        }
    ]
]


module.exports = {
    staticPermissions
}