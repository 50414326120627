import React, { useState, useRef, useEffect } from 'react';
import toast from 'react-hot-toast';
import { http } from '../utils/utils';
const validator = require('validator')


function FeaturesHome() {

  const [showResults, setShowResults] = React.useState(false)
  const [result, setResult] = useState('')
  let [email] = React.useState(false)

  const onSubmit = async () => {
    const receivedEmail = String(email)
    if (validator.isEmail(receivedEmail)) {

      try {
        const res = await http({
            method: 'POST',
            url: '/newsletter',
            form: {
                email
            }
        });

        const result = res.message
        setResult(result)
        setShowResults(true)
      } catch (e) {
          console.error(e);
          toast.error(e?.reason || `${e}`);
      }
    }
  }

  const handleEmailChange = function (e) {
    email = e.target.value
  }

  const Results = () => (
    <div id="subscription-success" className="search-results">
      <p className="text-center md:text-center mt-2 opacity-75 text-sm"><b>{result}</b></p>
    </div>
  )

  return (
    <section className="more-mb mt-5">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 more-mb">
        <div className="pt-32 pb-10 md:pt-40 md:pb-16">

          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

            {/* Content */}
            <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>
            <div className="md:col-span-8 lg:col-span-8 mb-8 md:mb-0 text-center md:text-left">
              <h2 className="h2 mb-4 font-red-hat-display font-extrabold text-center" data-aos="fade-down">
                Why BRIDGE
              </h2>
              <p className="text-l text-gray-600 dark:text-gray-400 text-center" data-aos="fade-down" data-aos-delay="150">
                <b>BRIDGE</b> provides strategies and technology <br></br>infrastructure services to help institutions professionally<br></br> approach the most promising DeFi investments
              </p>
              
            </div>
            <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>

          </div>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-12 md:py-20 border-gray-800">

              {/* Items */}
              <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 items-start md:max-w-2xl lg:max-w-none" data-aos-id-cards2>

                {/* 1st item */}
                <div className="relative flex flex-col blue-card" data-aos="fade-up" data-aos-anchor="[data-aos-id-cards2]">
                  <img className="mb-4 w-16 blue-card-img" src={require('../images/card-img-1.png')} width="230" height="230" alt="Onboarding" />
                  <h4 className="h4 mb-2 text-white text-left">Technology<br/> 
                    <br/>
                  </h4>
                  <p className="text-lg text-gray-600 dark:text-gray-400 text-left mt-4"><b>BRIDGE</b> team is composed
                    by technologists and expert DeFi analysts. <br></br>We design and implement competitive DeFi infrastructures and strategies</p>
                </div>

                {/* 2nd item */}
                <div className="relative flex flex-col blue-card " data-aos="fade-up" data-aos-anchor="[data-aos-id-cards2]" data-aos-delay="300">
                  <img className="mb-4 w-16 blue-card-img" src={require('../images/card-img-2.png')} width="230" height="230" alt="Onboarding" />
                  <h4 className="h4 mb-2 text-white text-left">Focused on institutions</h4>
                  <p className="text-lg text-gray-600 dark:text-gray-400 text-left mt-4">DeFi is still for early adopters and few specialized funds. <b>BRIDGE</b> focuses on institutions that are missing out on this great opportunity</p>
                </div>

                {/* 3rd item */}
                <div className="relative flex flex-col blue-card" data-aos="fade-up" data-aos-anchor="[data-aos-id-cards2]" data-aos-delay="600">
                  <img className="mb-4 w-16 blue-card-img" src={require('../images/card-img-3.png')} width="220" height="220" alt="Onboarding" />
                  <h4 className="h4 mb-2 text-white text-left">Bridging TradFi <br></br>and DeFi</h4>
                  <p className="text-lg text-gray-600 dark:text-gray-400 text-left mt-4">
                  There is a clear lack of regulated products to access the market.<br></br> We <b>BRIDGE</b> Traditional Finance and DeFi
                  </p>
                  <div id="contact-form" ></div>
                </div>
              </div>
            </div>
          </div>          
        </div> 
        {/* CTA form */}
              {<form>
                <div className="row">
                    <h4 className="h4 mb-4 font-red-hat-display font-extrabold text-center" data-aos="fade-down">
                      Interested? Let's talk
                    </h4>
                </div>

                    <div className="text-xs flex flex-row w-full md:max-w-2xl md:text-sm lg:max-w-2xl xl:max-w-2xl sm:flex-row justify-center  mx-auto">   
                      <input
                        type="email"
                        onChange={handleEmailChange}
                        className="form-input w-7/12 sm:w-5/12 md:w-9/12 mb-2 sm:mb-0 mr-2 "
                        placeholder="Your e-mail"
                        aria-label="Email address"
                        data-aos="fade-right"
                      />
                      <a className="md:text-sm text-lightblue-100 hover:text-gray-200 px-4 py-3 flex items-center justify-center transition duration-150 ease-in-out rounded border border-lightblue w-5/12 sm:w-3/12 sm:text-xs" 
                         onClick={onSubmit} href="#0" data-aos="fade-left">
                         Get in touch
                      </a>
                    </div>

                    {/* Success message */}
                    {showResults ? <Results /> : null}
                     
              </form>}
      </div>
    </section>
  );
}

export default FeaturesHome;
