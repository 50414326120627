import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import toast from 'react-hot-toast';
import { FormInput } from '../../utils/FormInput';
import { UserContext } from '../../utils/UserProvider';
import { http } from '../../utils/utils';

export default function MIFID() {
    const [open, setOpen] = useState(false);
    const [recapTable, setRecapTable] = useState([]);
    const [questions, setQuestions] = useState([]);
    const answers = [];

    const userContext = useContext(UserContext);
    const mifidCheck = userContext.me?.verification?.MIFID || [];
    const MIFIDDate = userContext.me?.verification?.MIFIDCompletedAt || '';

    const formatDate = () => {
        let result = '';
        if(MIFIDDate != '')
        {
            const date = new Date(MIFIDDate)
            result =  date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' at '+date.getHours()+':'+(date.getMinutes()<10?'0':'') + date.getMinutes();
        }
        return result.toString();

    }

    const form = useForm({ defaultValues: '' });
    const { formState, handleSubmit, errors } = form;

    const contentStyle = { background: '#000b28', 'max-height' : '90%', 'min-width': '50rem', 'max-width': '90rem', 'border-radius': '0.5rem', 'border-color': '#203b5a', 'width': 'auto', 'overflow-y': 'scroll' };

    const closeModal = () => setOpen(false); 
    const changeOpenStatus = async () => {
        setOpen(!open);
    }

    const getQuestions = async () =>
    {
        let res = ''
        try {
            res = await http({
                method: 'GET',
                url: '/mifid',
                });
            
        } catch (e) {
            
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
        return res;
    }

    const generateForm = async () =>
    {
        const res = await getQuestions();
        if(res != '')
        {
        setQuestions(res.questions);
        }
    }

    const generateQuestionRecap = async () => {

        const recap = userContext.me?.verification?.MIFID;

        const recapView = recap.map((e) => 
            <>
            <h4 className="h4 p-2 mt-9">{e.question}</h4>
            <p className="p-2 mt-2">{e.answer}</p>
            </>
        );
        setRecapTable(recapView);
    }


    useEffect(() => {
        if (questions.length == 0) { generateForm(); }
    }, [questions])

    useEffect(() => {
        if (recapTable.length == 0 && mifidCheck.length != 0) {
                generateQuestionRecap();
        } 
    }, [recapTable])

     const questionsList = questions.map((q, index) => 
            <>
            <h2 className="p-2 mt-9">{q.description}</h2>
                <FormInput
                        className="w-full p-2 mb-4"
                        name={index}
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'This question is required *' }
                        }}
                        render={({ name, className, ref }) => (
                            <select ref={ref} name={name} className={className}>
                                {q.options.map(o => {
                                    return <option value={o} name={o}>{o}</option>;
                                })}
                            </select>
                        )}
                />
                 
            </>
        );
    
    const onSubmit = async (answers) => {
        try {

            const answersArray = Object.entries(answers);

            const questionsAnswers = answersArray.map((a, index) => {
                return {
                    question: questions[index].description,
                    answer: a[1]
                }
            })
            
            const formData = new FormData();
            formData.append('MIFID', questionsAnswers)
            const res = await http({
                method: 'POST',
                url: '/verification/mifid',
                form: { MIFID: questionsAnswers },
            });
            const me = { ...document, ...res };
            userContext.update(me);

            closeModal();
            toast.success('Information updated successfully');
            generateQuestionRecap();
        } catch (e) {
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
    };

    return (
        <>
        {mifidCheck.length === 0 ? <button onClick={changeOpenStatus} className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-6">
                            Open MIFID
                        </button>
        : <>
            <h3 className="h3 mb-4 p-2">Your answers to MIFID survey</h3>
            <h3 className="h5 mb-4 p-2">Submitted on {formatDate()}</h3>
            <div className="bg-darkblue-200 text-gray-200 shadow-lg rounded-sm overflow-hidden pl-4 pb-4">
                {recapTable}
            </div>
          </>
        } 

        <Popup open={open} position="center center"  keepTooltipInside={false} onClose={closeModal} {...{contentStyle}}>
         
         <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={formState.isSubmitting}>
                <div className="mb-8">
                    <h2 className="h4 mb-4 p-2">MIFID questions</h2>
                    {questionsList}
                </div>
                
                <div className="flex flex-wrap mt-6">
                    <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-6 mb-2 ml-2 p-2">
                        Submit
                    </button>
                </div>
            </fieldset>
        </form>
        </Popup>
        </>
    );
}