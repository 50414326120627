import React, { useContext, useState, useEffect } from 'react';
import { withRouter} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const optionsData = [
  {
    id: '1',
    option: 'Putting savings in an high yield account, with minimal risk of losing the principal'
  },
  {
    id: '2',
    option: 'Enjoy yields denominated in stablecurrency, but also invest in the major liquid cryptocurrencies'
  },
  {
    id: '3',
    option: 'Diversified exposure across major cryptocurrency, DeFi assets and NFT'
  },
  {
    id: '4',
    option: 'Invest in high growth high/risks DeFi and NFT assets'
  },
]

function DragList() {
  const [options, setOptions] = useState(optionsData);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOptions(items);
    }

  return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="options">
            {(provided) => (
              <ul className="options" {...provided.droppableProps} ref={provided.innerRef}>
                {options.map(({id, option}, index) => {
                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <p>
                            { option }
                          </p>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
  );
}

export default withRouter(DragList);