import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../../partials/Loading';
import CryptoCoinIcon from './CryptoCoinIcon'
import TransactionButton from './advisoryTransactionButton'
import { getDynamicOrderPlan } from './blockchainFunctions';
import { pools } from './assetList';
import arrow from '../../../images/arrow.png';


export default function AdvisoryOrderTable(props) {

    const [orderPlan, setOrderPlan] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { address, strategy, amount } = props;

    const balances = async () => 
    {
        setIsLoading(true);
        setOrderPlan(await getDynamicAdvisoryOrderPlan(strategy));
        setIsLoading(false);
    }

    useEffect(() => {
        if (orderPlan.length == 0) { balances(); }
    }, [orderPlan])


    /*
    [
      {
          "from": "IdleUSDC v4 [Best yield]",
          "fromDecimals": 18,
          "balance": 0,
          "to": "USD Coin",
          "transaction": "idle"
      }
    ]*/


    const getDynamicAdvisoryOrderPlan = async (strategy) => {
      const newStrategy = await strategy.map((s) => {
        return {
          from: 'USD Coin',
          to: s.label,
          fromDecimals: 6,
          balance: (s.value / 100) * amount,
          transaction: (s.label == 'IdleUSDC v4 [Best yield]') ? 'idleMint' : 'paraswap'
        }
      })

      return newStrategy;
    }

    const listOfTx = orderPlan.map(tx => 
            <>
            <tr>
            {tx.balance.toFixed(2) >= 0.01 
                ? <td>{tx.balance.toFixed(2)}</td> 
                : tx.balance < 1e-12
                    ? <td> {'0'} </td>
                    : <td> {'<0.01'} </td>
            }
            <CryptoCoinIcon name={tx.from}/>
            <td><img src={arrow} height="50" width="50"/></td>
            <CryptoCoinIcon name={tx.to}/>
            <TransactionButton balances={balances} fromDecimals={tx.fromDecimals} fromName={tx.from} toName={tx.to} transaction={tx.transaction} amount={tx.balance} address={address} disabled={(tx.balance <= 1e-12)}/>
            </tr>         
            </>
        );

    return (
         <Loading if={isLoading}>
         <table class=" table-auto p-7">
                  <thead className="mb-4">
                    <tr>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">FROM</th>
                      <th scope="col"></th>
                      <th scope="col">TO</th>
                      <th scope="col">ACTION</th>
                      <th scope="col">FILLED</th>
                    </tr>
                  </thead>
                  <tbody className="order-tbody">
                    {listOfTx}
                  </tbody>
                </table>
            </Loading>
    );
}