import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FormInput } from '../../utils/FormInput';
import { withRouter} from 'react-router-dom';
import { ResponsivePieCanvas } from "./chart";
import { Web3Context } from '../../utils/Web3Provider';
import AdvisoryOrderTable from '../Pools/OrderPlan/advisoryOrderTable'
import Popup from 'reactjs-popup';


const strategyWeights = [ 0.7 , 0.3 ];
const contentStyle = { background: '#000b28', 'min-width': '50rem', 'max-width': '90rem', 'border-radius': '0.5rem', 'border-color': '#203b5a', 'width': 'auto' };

const Assets = [
[{'coin' : 'IdleUSDC v4 [Best yield]', 'weight' : 1}],  
[{'coin' : 'IdleUSDC v4 [Best yield]', 'weight' : 0.5}, {'coin' : 'Bankless Bed', 'weight' : 0.5}],
[{'coin' : 'Bankless Bed', 'weight' : 0.9}, {'coin' : 'Metaverse Index', 'weight' : 0.1}],
[{'coin' : 'DefiPulse Index', 'weight' : 0.8}, {'coin' : 'Metaverse Index', 'weight' : 0.2}],
]


const colorsPie = ['#f7da00', '#0aff2f', '#1a3087', '#ff0a64'];

function Portfolio(props) {
    const { formData, formFilled } = props
    const [strategy, setStrategy] = useState([])
    const [open, setOpen] = useState(false);
    const [amount, setAmount] = useState(0);


    
    const buildStrategy = () =>
    {
        const strategyArr = [];
        if(formFilled)
        {
            strategyWeights.map((w, index) => {
                Assets[formData.options[index].id].map(item => {
                    strategyArr.push({'id' : item.coin , 'label' : item.coin, 'value' : parseInt(parseFloat((w * item.weight).toFixed(2)) * 100) })
                });
            });
            const updatedStrategy = deleteRedundant(strategyArr);
            setStrategy(updatedStrategy);
        }
    } 

    const setAmountInput = async (e) => {
        setAmount(e.target.value)
    }

    const deleteRedundant = (list) =>
    {
        let listMod = []; 
        for(let i = 0; i < list.length; i++)
        {
            let found = false;
            for( let y = i+1; y < list.length; y++)
            {
                if(list[i].label == list[y].label)
                {
                    found = true;
                    listMod.push({'id' : list[i].id, 'label' : list[i].label, 'value' : (parseInt(list[i].value) + parseInt(list[y].value))});
                    list.splice(y,1)
                }
            }
            if(!found)
            {
                listMod.push(list[i]);
            }
        }
        return listMod;
    }


    const changeOpenStatus = async () => {
        setOpen(!open);
    }

    let {
        loadWeb3
    } = useContext(Web3Context);

    const connectWallet = () => {
        loadWeb3();
    }

    useEffect(() => {
        buildStrategy();
    },[formFilled, formData]);


    const form = useForm({
        defaultValues: {
            amountType: 'USDC',
        }
    });

    const { formState, handleSubmit, errors, control, watch, getValues } = form;
     

    return (
        <>
            <div className="mt-4">
                <h4 className="h4 p-2 mt-9 mb-4">Your strategy</h4>
            </div>
            <div className="invesSbx01 h-100">
                <div className="col-span-6 row-span-3 md:pt-4" style={{ minHeight: "300px", height: "20vh"}}>
                <ResponsivePieCanvas data={strategy} colorsArray={colorsPie}/>
                </div>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-2">
                <form className="col-span-1">
                        <fieldset disabled={formState.isSubmitting}>
                            <div className="block text-sm font-medium mb-3 text-left">
                                    <span className="text-darkblue-400">Amount</span> <span className="text-lightblue">*</span>
                            </div>
                            <FormInput
                                className="flex-grow text-left required"
                                label=""
                                name="amount"
                                form={form}
                                errors={errors}
                                validation={{
                                    required: { value: true, message: 'You must enter an amount' },
                                }}
                                render={({ name, className, ref, label }) => (
                                    <input
                                        ref={ref}
                                        name={name}
                                        type="number"
                                        step={getValues('amountType') === 'EUR' ? '0.01' : '0.00000000000001'}
                                        className={className}
                                        placeholder='Amount'
                                        autoFocus
                                        value={amount}
                                        onChange={(e) => { setAmountInput(e) }}
                                    />
                                )}
                            />
                        </fieldset>
                    </form>
                    {!props.connectionStatus && (
                        <div className="col-span-2">
                            <button
                                type="button"
                                className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 w-full text-center mb-2"
                                onClick={() => connectWallet()}
                            >
                                Connect wallet
                            </button>
                        </div>
                    )}
                    {props.connectionStatus && ( <div className="col-span-2">
                        <button onClick={changeOpenStatus} className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-15 uppercase">Generate Order Plan</button>
                    </div>)}
            </div>
            <Popup open={open} position="center center" keepTooltipInside={false} onClose={() => setOpen(false)} {...{ contentStyle}}>
                <div class="dark flex-grow text-center p-5">
                    <AdvisoryOrderTable amount={amount} strategy={strategy} address={props.address}/>
                </div>
            </Popup>
        </>
    );
}
export default withRouter(Portfolio);