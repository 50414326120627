
import Header from '../partials/Header/Header';
import { http } from '../utils/utils';
import React, { useContext, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { FormInput } from '../utils/FormInput';
import { EMAIL_REGEX } from '../utils/utils';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '@material-ui/core/Button';
import { UserContext } from '../utils/UserProvider';
import Footer from '../partials/Footer';
import { useEffect } from 'react';

function WorkWithUs() {
  const form = useForm();
  const { formState, errors, handleSubmit } = form;
  const history = useHistory();
  const userContext = useContext(UserContext);

  const onSubmit = async (formData) => {
    const form = new FormData()
    form.append('resume', formData.resume[0]);
    form.append('email', formData.email)
    form.append('lastName', formData.lastName)
    form.append('firstName', formData.firstName)
    form.append('startDate', formData.startDate)
    form.append('position', formData.position)

    try {
      const res = await http({
        method: 'POST',
        url: '/work-with-us',
        form: form
      }); 

      toast.success('Job application submitted!')
    } catch (e) {
          console.log(e);
          toast.error('We are accepting only the following file formats: pdf, docx');
        }
  };
  
  const [fileName, setFileName] = useState('')

  const showFileName = () => {
    let input = document.querySelector('#getFile');
    if (input) {
     
      input.addEventListener( 'change', function( e )
      {
        var fileName = '';
        
        fileName = e.target.value.split( '\\' ).pop();
        if(fileName)
          setFileName(fileName)
       
      });
    }
  }

  useEffect(() => {
    showFileName();
  }, [document.querySelector('#getFile')])
  
  showFileName()

  return (
    <div className="flex flex-col min-h-screen overflow-hidden work-sans">

      {/*  Site header */}
      <Header />
      {/*  Page sections */}
      <section className="more-mb mt-5 z-0 relative">
        <div className="max-w-6xl mx-auto md:px-6 more-mb">
          <div className="md:pt-32 md:pb-16">

            {/* Hero content */}
            <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

              {/* Content */}
              <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>
              <div className="md:col-span-8 lg:col-span-8 mb-8 md:mb-0 text-center md:text-left max-w-6xl">
                <h2 className="h2 text-5xl md:mb-8 font-bold text-center" data-aos="fade-down">
                  Work with us
                </h2>
                <div className="mb-8 md:mb-0 text-center"></div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={formState.isSubmitting}>
                      <div className="block font-bold mb-2 text-left">
                        <span className="text-white">First Name</span> <span className="text-lightblue">*</span>
                      </div>
                      <FormInput
                        label=""
                        name="firstName"
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'You must enter your name' }
                        }}
                        render={({ name, className, ref, label }) => (
                            <input ref={ref} name={name} className={className} placeholder={label} autoFocus />
                        )}
                      />
                      <div className="block font-bold mb-2 text-left">
                        <span className="text-white">Last Name</span> <span className="text-lightblue">*</span>
                      </div>
                      <FormInput
                        label=""
                        name="lastName"
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'You must enter your last name' }
                        }}
                        render={({ name, className, ref, label }) => (
                            <input ref={ref} name={name} className={className} placeholder={label} />
                        )}
                      />
                      <div className="block font-bold mb-2 text-left">
                        <span className="text-white">Email</span> <span className="text-lightblue">*</span>
                      </div>
                      <FormInput
                        label=""
                        name="email"
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'You must enter your email' },
                            pattern: EMAIL_REGEX
                        }}
                        render={({ name, className, ref, label }) => (
                            <input
                                ref={ref}
                                name={name}
                                className={className}
                                placeholder={label}
                                type="email"
                                autoFocus
                            />
                        )}
                      />
                      <div className="block font-bold mb-2 text-left">
                        <span className="text-white">Open positions</span> <span className="text-lightblue">*</span>
                      </div>
                      <FormInput
                        className=""
                        label=""
                        name="position"
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'You must select an open position' },
                        }}
                        render={({ name, className, ref, label }) => (

                            <select ref={ref} name={name} className={className} placeholder={label} >
                                  <option value='Solidity Developer' name='solidity-developer'>Solidity Developer</option>
                                  <option value='Data Scientist' name='data-scientist'>Data Scientist</option>
                            </select>
                        )}
                      />
                      <div className="block font-bold mb-2 text-left">
                        <span className="text-white">Potential start date</span> <span className="text-lightblue">*</span>
                      </div>
                      <FormInput
                        className="w-full"
                        label=""
                        name="startDate"
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'You must enter a potential start date' },
                            validate: value => isNaN(new Date(value.split('/').reverse())) ? 'Invalid date' : true,
                        }}
                        render={({ name, className, ref }) => (
                            <input ref={ref} name={name} className={className} type="date" value={form.defaultValues}/>
                        )}
                      />
                      <div className="block font-bold mb-2 text-left">
                        <span className="text-white">Attach Your Resume</span> <span className="text-lightblue">*</span>
                      </div>
                      <div className="flex flex-row">
                        <div className="w-full md:w-3/5 flex flex-row">
                          <FormInput
                            className=""
                            label=""
                            name="resume"
                            form={form}
                            errors={errors}
                            validation={{
                                required: { value: true, message: 'You must upload a resume' }
                            }}
                            render={({ name, className, ref, label }) => (
                                <>
                                    <label htmlFor="getFile">
                                      <Button variant="outlined" component="span" className="btn p-c uppercase btn-form-upload hover:text-gray-200 transition duration-150 ease-in-out">
                                        Choose File
                                      </Button>
                                    </label>
                                    <input type="file" id="getFile" className="hidden" ref={ref} name={name}/>
                                </>
                            )}
                          />
                          <span id="myFileName" className="ml-5 text-medium text-lightblue-100 font-bold">{fileName}</span>
                        </div>
                        <div className="md:w-1/5">
                        </div>
                        <div className="hidden md:w-1/5 md:block lg:block lg:w-1/5">
                          <button type="submit" className="btn font-bold uppercase text-white bg-lightblue-100 hover:bg-lightblue-100 w-full">
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="w-full md:hidden lg:hidden">
                        <button type="submit" className="btn font-bold uppercase text-white bg-lightblue-100 hover:bg-lightblue-100 w-full">
                          Submit
                        </button>
                      </div>
                    </fieldset>
                  </form> 
                </div>
              </div>
          </div>
        </div>
      </section>
  
      
      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default WorkWithUs;