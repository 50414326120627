import React from 'react';

export default function LogoSUSD(props) {
    return (
        <svg viewBox="0 0 60 60" width={props.width} height={props.height} className={props.className}>
            <ellipse fill="#fff" cx="30" cy="30.129" rx="30" ry="29.67"/>
            <path fill="#17132c" d="M25.69 28.178c.803 0 1.455.66 1.455 1.476 0 .815-.652 1.476-1.456 1.476h-3.778c-3.659 0-6.625-3.008-6.625-6.718s2.966-6.717 6.625-6.717h9.744c.804 0 1.455.66 1.455 1.475 0 .815-.651 1.476-1.455 1.476H21.91c-2.051 0-3.714 1.686-3.714 3.766s1.663 3.766 3.714 3.766h3.778z"/>
            <path fill="#16122a" d="M22.707 31.13c-.804 0-1.456-.661-1.456-1.476s.652-1.476 1.456-1.476h3.778c3.659 0 6.625 3.008 6.625 6.718s-2.966 6.717-6.625 6.717H16.74c-.804 0-1.455-.66-1.455-1.476 0-.815.651-1.475 1.455-1.475h9.744c2.051 0 3.714-1.686 3.714-3.766s-1.663-3.766-3.714-3.766h-3.778z"/>
            <path fill="#8f8d98" fillOpacity=".5" d="M17.892 41.017l-2.367-1.719L30.408 18.24l2.367 1.72z"/>
            <path fill="#17132c" transform="rotate(-90 38.02 3.112)" d="M2.35.547v4.719c0 .658.126 1.154.378 1.487.252.333.65.5 1.192.5.543 0 .942-.167 1.198-.5.256-.333.384-.829.384-1.487V.547h1.72v4.626c0 1.162-.281 2.049-.843 2.66-.562.613-1.381.92-2.459.92-1.077 0-1.895-.307-2.453-.92C.91 7.223.63 6.336.63 5.174V.547h1.72zm7.554 5.486c.03.402.193.726.487.97s.686.366 1.175.366c.405 0 .736-.087.992-.261a.831.831 0 0 0 .384-.727c0-.217-.071-.39-.212-.517-.142-.128-.34-.229-.596-.302a16.77 16.77 0 0 0-1.187-.273c-.527-.101-.97-.23-1.33-.39a2.11 2.11 0 0 1-.877-.709c-.225-.313-.338-.73-.338-1.249 0-.473.128-.895.384-1.267A2.598 2.598 0 0 1 9.852.797c.455-.213.969-.32 1.542-.32.589 0 1.118.11 1.588.331.47.221.84.525 1.112.913a2.3 2.3 0 0 1 .418 1.313h-1.685a1.235 1.235 0 0 0-.436-.842c-.252-.214-.584-.32-.997-.32-.382 0-.688.081-.917.244-.23.163-.344.395-.344.697 0 .217.069.386.207.506.137.12.332.217.584.29.252.074.642.161 1.17.262.534.1.985.226 1.352.378.367.15.663.38.888.685.226.306.339.715.339 1.226 0 .496-.134.94-.402 1.331-.267.391-.64.7-1.117.924a3.738 3.738 0 0 1-1.61.337c-.643 0-1.214-.118-1.715-.354a2.849 2.849 0 0 1-1.174-.982 2.524 2.524 0 0 1-.436-1.407l1.685.024zm5.8-5.486h2.786c.802 0 1.51.172 2.126.517a3.56 3.56 0 0 1 1.422 1.441c.332.616.498 1.32.498 2.11 0 .79-.166 1.493-.498 2.11a3.56 3.56 0 0 1-1.422 1.44c-.615.345-1.324.517-2.126.517h-2.786V.547zM18.49 7.23c.726 0 1.29-.239 1.69-.715.402-.477.602-1.11.602-1.9 0-.79-.2-1.424-.601-1.9C19.779 2.237 19.216 2 18.49 2h-1.066v5.23h1.066z"/>
       </svg>
    );
}