import React from 'react';
import LogoETH from './LogoETH';
import LogoUSDC from './LogoUSDC';
import LogoADAI from './LogoADAI';
import LogoAUSDT from './LogoAUSDT';
import LogoAUSDC from './LogoAUSDC';
import LogoDAI from './LogoDAI';
import LogoSUSD from './LogoSUSD';
import LogoUSDT from './LogoUSDT';
import LogoBUSD from './LogoBUSD';
import LogoDPI from './LogoDPI';
import LogoCurve from './LogoCURVE';
import LogoIDLEUSDC from './LogoIDLEUSDC';
import LogoYEARN from './LogoYEARN';
import LogoMVI from './LogoMVI';
import LogoBED from './LogoBED';



export const CRYPTO_CURRENCIES = [
    {
        image: () => <LogoETH width="1.8rem" />,
        name: 'Ethereum',
        symbol: 'ETH',
    },
    {
        image: () => <LogoUSDC width="1.8rem" />,
        name: 'USD Coin',
        symbol: 'USDC',
    },
    {
        image: () => <LogoADAI width="1.8rem" />,
        name: 'Aave Dai',
        symbol: 'aDAI',
    },
    {
        image: () => <LogoAUSDT width="1.8rem" />,
        name: 'Aave Tether',
        symbol: 'aUSDT',
    },
    {
        image: () => <LogoAUSDT width="1.8rem" />,
        name: 'aTether',
        symbol: 'aUSDT',
    },
    {
        image: () => <LogoAUSDC width="1.8rem" />,
        name: 'Aave interest bearing USDC',
        symbol: 'aUSDC',
    },
    {
        image: () => <LogoDAI width="1.8rem" />,
        name: 'Dai',
        symbol: 'DAI',
    },
    {
        image: () => <LogoSUSD width="1.8rem" />,
        name: 'sUSD',
        symbol: 'SUSD',
    },
    {
        image: () => <LogoUSDT width="1.8rem" />,
        name: 'Tether',
        symbol: 'USDT',
    },
    {
        image: () => <LogoBUSD width="1.8rem" />,
        name: 'BinanceUSD',
        symbol: 'BUSD',
    },
    {
        image: () => <LogoIDLEUSDC width="28rem" />,
        name: 'IdleUSDC v4 [Best yield]',
        symbol: 'IdleUSDC',
    },
    {
        image: () => <LogoCurve width="28rem" />,
        name: 'Curve.fi a3CRV Gauge Deposit',
        symbol: 'a3CRVgauge',
    },
    {
        image: () => <LogoCurve width="28rem" />,
        name: 'Curve.fi aDAI/aUSDC/aUSDT',
        symbol: 'a3CRV',
    },
    {
        image: () => <LogoCurve width="28rem" />,
        name: 'Curve.fi steCRV Gauge Deposit',
        symbol: 'steCRVgauge',
    },
    {
        image: () => <LogoCurve width="28rem" />,
        name: 'Curve.fi ETH/stETH',
        symbol: 'steCRV',
    },
    {
        image: () => <LogoDPI width="28rem" />,
        name: 'DefiPulse Index',
        symbol: 'DPI',
    },
    {
        image: () => <LogoYEARN width="28rem" />,
        name: 'USDC yVault',
        symbol: 'yvUSDC',
    },
    {
        image: () => <LogoYEARN width="28rem" />,
        name: 'DAI yVault',
        symbol: 'yvDAI',
    },
    {
        image: () => <LogoMVI width="28rem" />,
        name: 'Metaverse Index',
        symbol: 'MVI',
    },
    {
        image: () => <LogoBED width="28rem" />,
        name: 'Bankless Bed',
        symbol: 'BED',
    },

];