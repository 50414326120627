import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useParams, withRouter} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LoggedPage from '../../partials/LoggedPage';
import Loading from '../../partials/Loading';
import { FormInput } from '../../utils/FormInput';
import { http } from '../../utils/utils';
import DragList from './dragList';



function DragAndDrop(props) {
 

    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [options, setOptions] = useState([]);
    const [slideValue, setSlideValue] = useState(5);

 
    const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOptions(items);
    const retObj = { 'id' : props.id, 'options' : items };
    props.parentCallBack(retObj);
    }

    useEffect(() => {
        
        let optionData = [];
        props.options.map((o,index) => {
            optionData.push(
                {
                id: (index).toString() ,
                option: o
                })

        });
        setOptions(optionData)
        const retObj = { 'id' : props.id, 'options' : optionData };
        props.parentCallBack(retObj);

    },[slideValue])
    

    return (

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="options">
                    {(provided) => (
                      <ul className="options form-input" {...provided.droppableProps} ref={provided.innerRef}>
                        {options.map(({id, option}, index) => {
                          return (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(provided) => (
                                <li className="border" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <p className='p-2 mb-3'>
                                    &#8226; { option }
                                  </p>
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
    );
}
export default withRouter(DragAndDrop);