import React from 'react';

function FeaturesBlocks() {
  return (
    <section className="feature-section-bg z-m-1 relative">

      
          <div className="square sqBgDark2 h-16 w-16  hidden absolute top-0 left-0 mt-44 ml-48 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay="200"></div>
          <div className="square sqBgDark1 h-32 w-32  hidden absolute top-0 left-0 mt-52 ml-24 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay="500"></div>
          <div className="square bg-white h-60 w-60  hidden absolute top-0 left-0 mt-12 -ml-20 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay="800"></div>
          
          <div className="square sqBgDark0 h-6 w-6  hidden absolute top-0 right-0 mt-16 mr-44 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay="200"></div>
          <div className="square sqBgDark2 h-12 w-12  hidden absolute top-0 right-0 mt-28 mr-40 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay="500"></div>
          <div className="square bg-white h-16 w-16  hidden absolute top-0 right-0 mt-12 mr-56 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay="800"></div>
        
          <div className="square sqBgDark2 h-16 w-16 hidden absolute bottom-0 left-0 mb-12 ml-12 z-10 2xl:inline-block" data-aos="zoom-in" data-aos-delay="500"></div>
          <div className="square sqBgDark3 h-20 w-20 hidden absolute bottom-0 left-0 mb-20 ml-20 z-10 2xl:inline-block" data-aos="zoom-in" data-aos-delay="800"></div>
        
          <div className="square sqBgDark2 h-32 w-32 hidden absolute bottom-0 right-0 mb-52 mr-0 z-10 2xl:inline-block" data-aos="zoom-in" data-aos-delay="500"></div>
          <div className="square sqBgDark3 h-20 w-20 hidden absolute bottom-0 right-0 mb-72 mr-24 z-10 2xl:inline-block" data-aos="zoom-in" data-aos-delay="200"></div>
          <div className="square sqBgDark0 h-32 w-32 hidden absolute bottom-0 right-0 mb-28 mr-12 z-10 2xl:inline-block" data-aos="zoom-in" data-aos-delay="800"></div>
          


      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="text-black max-w-2xl mx-auto text-center pb-12 md:pb-12" data-aos-id-cards1>
            <h2 className="h2 mb-4 text-dark-blue-bridge">BRIDGE brings professional investors to the future of finance</h2>
            <p className="text-xl text-blue-bridge extra-mb">Why DeFi</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 items-start md:max-w-2xl lg:max-w-none" >

            {/* 1st item */}
            <div className="relative flex flex-col card " data-aos="fade-up" data-aos-anchor="[data-aos-id-cards1]" data-aos-anchor-placement="top-center">
              <img className="mb-4 w-16 img-card" src={require('../images/first-img.png')} width="230" height="230" alt="Onboarding" />
              <h4 className="h3 mb-2 text-dark-blue-bridge text-left">1.</h4>
              <h4 className="h4 mb-2 text-dark-blue-bridge text-left">Opportunity for institutions</h4>
              <p className="text-lg text-blue-bridge text-left">DeFi market has been so far populated only by early adopters and presents great opportunities for the lack of sophisticated players</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col card " data-aos="fade-up" data-aos-anchor="[data-aos-id-cards1]" data-aos-anchor-placement="top-center" data-aos-delay="300">
              <img className="mb-4 w-16 img-card" src={require('../images/2nd-img.png')} width="230" height="230" alt="Onboarding" />
              <h4 className="h3 mb-2 text-dark-blue-bridge text-left">2.</h4>
              <h4 className="h4 mb-2 text-dark-blue-bridge text-left">A new paradigm</h4>
              <p className="text-lg text-blue-bridge text-left">DeFi is already investable and will gradually integrate and partially substitute existing financial infrastructure, radically changing the asset management landscape</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col card" data-aos="fade-up" data-aos-anchor="[data-aos-id-cards1]" data-aos-anchor-placement="top-center" data-aos-delay="600">
              <img className="mb-4 w-16 img-card extra-right" src={require('../images/3rd-img.png')} width="220" height="220" alt="Onboarding" />
              <h4 className="h3 mb-2 text-dark-blue-bridge text-left">3.</h4>
              <h4 className="h4 mb-2 text-dark-blue-bridge text-left">Diversification opportunity</h4>
              <p className="text-lg text-blue-bridge text-left"> It is now possible to easily
invest in the future of
finance, currently
accessible only to
technologyists and crypto
experts
              </p>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
