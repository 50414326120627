import moment from 'moment';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { http } from '../../utils/utils';

export default function MIFIDRecap(props) {
    const { user, setUser } = props;
    const [recapTable, setRecapTable] = useState([]);

    const mifidCheck = user.verification.MIFID || [];
    const MIFIDDate = user.verification.MIFIDCompletedAt || '';

    const generateQuestionRecap = async () => {

        const recap =user.verification.MIFID;

        const recapView = recap.map((e) => 
            <>
            <div>
            <h4 className="h5 p-2 mt-3 font-bold">{e.question}</h4>
            <p className="p-2 mt-2">{e.answer}</p>
            </div>
            </>
        );

        setRecapTable(recapView);
    }

    useEffect(() => {
        if (recapTable.length == 0 && mifidCheck.length != 0) { generateQuestionRecap(); }
    }, [recapTable])

    return (
     <>
        {mifidCheck.length === 0 ?  <h3 className="h5 mb-4 p-2 font-bold">MIFD survey not complete</h3> 
        : <>
            <h3 className="h5 mb-4 p-2 font-bold">Answers to MIFID survey</h3>
            <h3 className="h5 mb-4 p-2">Submitted on {MIFIDDate}</h3>
            <div className="bg-darkblue-200 text-gray-200 shadow-lg rounded-sm overflow-hidden pl-4 pb-4">
                <div className="grid grid-cols-2 gap-4">
                 {recapTable}
                 </div>
            </div>
          </>
        }  
    </>
    );
}