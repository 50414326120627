import { queryTokensTheGraph } from './blockchainFunctions';
import Web3 from 'web3';
import { paraswapTokens } from './assetList';
const { ParaSwap, ParaswapFeed, Token}  = require('paraswap');
const BN = require("bignumber.js");

const referrer = "bridge";
const SLIPPAGE = 1;//1%

let web3;
if (window.web3) {
  web3 = new Web3(window.web3.currentProvider);
}

const networks = {
	MAINNET: 1,
	POLYGON: 137
}

export class ParaSwapper {
  	constructor(network){
    	this.web3Provider = web3.currentProvider;
		this.paraSwap = new ParaSwap(network).setWeb3Provider(web3.currentProvider);
  	}
   	t(name, network, tokens) {
			network = network || networks.MAINNET;

			return tokens[network].find(t => t.name === name);
		}
	async getChainId()
	{
		return await web3.eth.net.getId();
	}

  	async getRate(from, to, amount) {
      	return this.paraSwap.getRate(from.address, to.address, amount, 'SELL', {referrer}, from.decimals, to.decimals);
  		}

		//Get augustus contract to be authorized to spend
  	async getSpender() {
      	return this.paraSwap.getSpender();
  		}

  	async buildSwap(from, to, srcAmount, minAmount, priceRoute, userAdderss) {
      	return this.paraSwap.buildTx(from.address, to.address, srcAmount, minAmount, priceRoute, userAdderss, referrer);
  		}

  	async approve(amout, userAddress, from){
  		return this.paraSwap.approveToken(amout, userAddress, from.address);
  		}
		  
  	async getAllowance(userAddress,from){
  		return this.paraSwap.getAllowance(userAddress, from.address);
  		}

	async buildSwapLocally(from, to, srcAmount, minAmount, priceRoute, userAdderss) {
			// We need this to test on hardhat locally
			// From paraswap sdk
			// async buildTxLocally(
			// 	srcToken: Token,
			// 	destToken: Token,
			// 	srcAmount: string,
			// 	destAmount: string,
			// 	priceRoute: OptimalRatesWithPartnerFees,
			// 	userAddress: string,
			// 	referrer: string,
			// 	referrerIndex: number,
			// 	gasPrice: string,
			// 	receiver: string = NULL_ADDRESS,
			// 	options: BuildOptions = {},
			//   ) {
			const gasPrice = await web3.eth.getGasPrice();
			//ReferrerIndex, unimportant
			const referrerIndex = 0;
				return this.paraSwap.buildTxLocally(from, to, srcAmount, minAmount, priceRoute, userAdderss, referrer, referrerIndex, gasPrice);
			}
		
	//builds and execute swap
	async swapHardhat(_srcAmount, nameFrom, nameTo, userAddress, vaultAddress = '0x8ee084b76651d7fa501b175f3ac6585bdead04fc') {
		
		let tokenList = paraswapTokens;
		const tokenFetched = await queryTokensTheGraph(vaultAddress);
		tokenList = tokenList.concat(tokenFetched);

		const tokens = { [networks.MAINNET]: tokenList,}
		const from = this.t(nameFrom,1,tokens);
		const to = this.t(nameTo,1,tokens)
		
		try {
			const currentChainId = await this.getChainId();
			
			const srcAmount = new BN(_srcAmount).times(10 ** from.decimals).toFixed(0);
  		    const priceRoute = await this.getRate(from, to, srcAmount);
			const minAmount = new BN(priceRoute.destAmount).times(1 - SLIPPAGE / 100).toFixed(0);
  
			let transaction 
			let tokenFrom;
			let tokenTo;

			if (from.symbol === "ETH") {
				transaction = await this.buildSwap(from, to, srcAmount, minAmount, priceRoute, userAddress);
			} 
			else 
			{

				if( currentChainId == 1)
				{

    				const approval = await this.approve(srcAmount, userAddress, from);
    				const allow1 = await this.getAllowance(userAddress, from);
    				transaction = await this.buildSwap(from, to, srcAmount, minAmount, priceRoute, userAddress);
				}
				else if( currentChainId == 31337)
				{
					tokenFrom = new Token(from.address, from.decimals, from.symbol, "ERC20", '', [], 1, "", srcAmount, srcAmount )
					tokenTo = new Token(from.address, from.decimals, from.symbol, "ERC20", '', [], 1, "", srcAmount, srcAmount )
					
					const transactionToMod = await this.buildSwapLocally(tokenFrom, tokenTo, srcAmount, minAmount, priceRoute, userAddress);
				
					transaction = {
					from: transactionToMod.from,
					to : transactionToMod.to,
					value: transactionToMod.value,
					data: transactionToMod.data,
					gas: transactionToMod.gas,
					gasPrice: transactionToMod.gasPrice }

				}
			}
			await web3.eth.sendTransaction(transaction);	
		} 
		catch (error) { console.error(error);}
  	}
}


