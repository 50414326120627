import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { InlineWidget } from "react-calendly";
import toast from 'react-hot-toast';
import { FormInput } from '../../utils/FormInput';
import { UserContext } from '../../utils/UserProvider';
import { http } from '../../utils/utils';

export default function VerifyIdentity() {
    
    const userContext = useContext(UserContext);
    const firstName = userContext.me?.firstName || '';
    const lastName = userContext.me?.lastName || '';
    const email = userContext.me?.email || '';
    const booked = userContext.me?.verification?.callBooked || false;
    const calendlyLink = 'silvio-busonero-bridge/15min';

    const prefill = {
            email: email,
            name: firstName +' '+ lastName,
            customAnswers: {    a1: 'KYC Call', }
    }

    const pageSettings={
          backgroundColor: '203a59',
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          primaryColor: '41c0f0',
          textColor: 'ffffff'
    }

    return (
        <>
        <div>
        {!booked ? (
            <>
             <h3 className="h4 ml-3 mb-3 p-2 pl-5 text-center">Book your verification call</h3>
            <InlineWidget prefill={prefill} pageSettings={pageSettings}
            url={"https://calendly.com/"+calendlyLink} />
            </>
            )
         :<h3 className="h4 ml-3 mb-3 p-2 pl-5 text-center">Call booked</h3>
        }
        </div>
        </>
    );
}