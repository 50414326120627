import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import members from './Members'

function TeamCarousel() {

    const children = members.map((m) => {
            return (
                <div class="max-w-sm overflow-hidden shadow-lg">
                    <img class="w-full" src={m.pic} alt="Sunset in the mountains"/>
                    <div class="px-6 py-4 dark-blue-bridge">
                        <div class="font-bold text-xl mb-2">{m.name}</div>
                        <p className="mb-2 font-red-hat-display text-gray-400">{m.role}</p>
                        <p class="text-white">
                            {m.description}
                        </p>
                    </div>
                    <div class="px-6 pt-4 dark-blue-bridge text-white pb-5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                    </div>
                </div>
            )
        });
            
    
    return (
      <div className='carousel-container'>
        <Carousel 
            children={children} 
            hasMediaButton={false} 
            hasSizeButton={false} 
            hasDotButtons={'top'}
            hasCaptions={'bottom'}
            hasIndexBoard={false}
            hasThumbnails={false}
            rightIcon={<div className="white">
                         <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right fa-w-8 h-10 filter-white w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                       </div>
            }
            leftIcon={<div className="white">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left fa-w-8 h-10 filter-white w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>                       </div>
            }
            activeIcon={<svg width="12" height="12">
                          <rect width="20" height="20" style={{ fill:"DeepSkyBlue", stroke: "DeepSkyBlue", 'stroke-width': 5 }} />
                        </svg>
            }
            passiveIcon={<svg width="12" height="12">
                          <rect width="20" height="20" style={{ fill:"white", stroke: "white", 'stroke-width': 5}} />
                        </svg>
            }
        />
      </div>    
    );
}

export default TeamCarousel;
