import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useParams, withRouter} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LoggedPage from '../../partials/LoggedPage';
import Loading from '../../partials/Loading';
import { FormInput } from '../../utils/FormInput';
import { http } from '../../utils/utils';
import { UserContext } from '../../utils/UserProvider';
import DragList from './dragList';
import DragAndDrop from './dragAndDrop';



function AdvQuestions(props) {
 
    const form = useForm({ defaultValues: { active: true } });
    const { formState, handleSubmit, errors, getValues, reset, watch } = form;
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [options, setOptions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [slideValue, setSlideValue] = useState(5);
    const userContext = useContext(UserContext);

    const userId = userContext.me?._id || '';

    const back = (e) => {
        if (e) e.preventDefault();
        history.push('/pools');
    };
    const onSubmit = async (formData) => {
        try {
            const retObj = formData;
            retObj['options'] = options[0].options;
            props.parentCallBack(retObj);
            
            const answersArray = Object.entries(retObj);

            const questionsAnswers = answersArray.map((a, index) => {
                let answer = a[1];
                if(typeof(a[1]) === 'object')
                {
                    answer = a[1].map(opt => {
                        return opt.option;
                    });
                }
                return {
                    description: questions[index].description,
                    answers: answer
                }
            });

            const res = await http({
                method: 'PUT',
                url: '/advisory/'+userId+'/',
                form: { questions: questionsAnswers },
            });

            toast.success("Answers Updated")

        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || `${e}`);
        }
    };

    const handleCallBack = (data) =>
    {
        let arr = options;
        let found = false;
        arr.map((obj, index) => {
            if(obj.id == data.id)
            {
                arr[index] = data;
                found = true;
            }
        });

        if(!found) { arr.push(data); }
        setOptions(arr);
  
        if(options.length > 0)
        {
            let retObj = {};
            retObj['options'] = arr[0].options;
            props.parentCallBack(retObj);
        }
    }

    const handleSlideChange = (event) =>
    {
        setSlideValue(event.target.value);
    }
    
    const getQuestions = async () =>
    {
        try {
            const res = await http({
                method: 'GET',
                url: '/advisory',
                });
            return res;


        } catch (e) {
            
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
        
    }

    const generateForm = async () =>
    {
        try
        {
            const res = await getQuestions();
            if(res != '' && res.questions)
            {
                setQuestions(res.questions);
                setReady(true)
            }
        } catch (e) {
            
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }

    }



    useEffect(() => {
        if (questions.length == 0) { generateForm(); }
    },[reset, slideValue, questions])
    watch('name');
    
    const questionsList = questions.map((q, index) => 
             <>
            {q.type == "slider" &&(
                 <>
                <h2 className="p-2 mt-9">{q.description}</h2>     
                <FormInput
                    className="w-full p-2 mb-4"
                    name={'slider'+index}
                    form={form}
                    errors={errors}
                    validation={{
                        required: { value: true, message: 'This question is required *' }
                    }}
                    render={({ name, className, ref }) => (
                     <>
                     <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
                         <div className="col-span-3">
                            <input type="range"  ref={ref} name={name} value={slideValue} onChange={handleSlideChange} className={className} min="1" max={q.options[0]}/>
                         </div>
                         <div className="col-span-1 text-center my-auto">  
                            <label for="knowledge">{slideValue}</label>
                        </div>
                    </div>
                    </>
                    )}
                />
                </>
            )} 
            {q.type == "single choice" &&(
                 <>
                <h2 className="p-2 mt-9">{q.description}</h2>     
                 <FormInput
                    className="w-full p-2 mb-4"
                    name={'singleChoice'+index}
                    form={form}
                    errors={errors}
                    validation={{
                        required: { value: true, message: 'This question is required *' }
                    }}
                    render={({ name, className, ref }) => (
                        <select ref={ref} name={name} className={className}>
                        {q.options.map(o =>
                                <option value={o} name={o}>{o}</option>
                            )}
                        </select>
                    )}
                />
                </>
            )} 
            {q.type == "ranking" &&(
                 <>
                    <h2 className="p-2 mt-9">{q.description}</h2>
                    <div className="dark">
                        <DragAndDrop className="w-full p-2 mb-4 form-input" parentCallBack={handleCallBack} options={q.options} id={index}/>
                    </div>     
                </>
            )} 
             </>
        );
    return (
       <Loading if={!ready}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={formState.isSubmitting}>
                        {questionsList}
                    
                            <div className="flex flex-wrap mt-6">
                                <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-6 mb-2 ml-2 p-2">
                                    Submit
                                </button>
                            </div>
                    </fieldset>
                </form>

            </Loading>
    );
}
export default withRouter(AdvQuestions);