import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useParams, withRouter} from 'react-router-dom';
import LoggedPage from '../../partials/LoggedPage';
import Loading from '../../partials/Loading';
import { FormInput } from '../../utils/FormInput';
import RoleForm from './RoleForm'
import { http } from '../../utils/utils';
import { staticPermissions } from './permissions';


function Roles() {
 
    const [roles, setRoles] = useState([]);
    const [showNewRoleForm, setShowNewRoleForm] = useState(false);
    const [rolesTable, setRolesTable] = useState([]);
    const form = useForm({ defaultValues: { active: true } });
    const { formState, handleSubmit, errors, getValues, reset, watch } = form;
    const history = useHistory();
    const [ready, setReady] = useState(false);

    const back = (e) => {
        if (e) e.preventDefault();
        history.push('/pools');
    };

    const onSubmit = async (formData) => {

        const newRoleData = {
            name: formData.roleName,
            permissions: formData.permissions
        }

        try {

            await http({
                method: 'POST',
                url: '/role',
                form: newRoleData
            });
        
        toast.success('Role successfully created');
        fetchData();
        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || `${JSON.stringify(e.reason.message)}`);
        }
    };

    const changeShowStatus = async () => {
        setShowNewRoleForm(true);
    }
    
    const createRoleTable = async (roles) => {

        const table = await roles.map((e, index) => { 
            return (
                <RoleForm role={e} index={index}></RoleForm>
            )
        });

        setRolesTable(table);
    }

    const generatePermissionsCheckbox = (permissions, index) => {
        const permissionTable = permissions.map((r => {
            const operations = Object.entries(r[1])
            return (
                <>
                    <div className="w-2/12">
                        <h3 className="mt-2 uppercase">{r[0]}</h3>
                            {operations.map(o => {
                                return (
                                    <>
                                    <FormInput
                                                className="form-control mt-2"
                                                name={`permissions.${r[0]}.${o[0]}`}
                                                errors={errors}
                                                form={form}
                                                render={({ name, ref }) => (
                                                    <label className="flex items-center">
                                                        <input ref={ref} name={name} className="form-checkbox" 
                                                                         type="checkbox" defaultChecked={o[1]}/>
                                                        <span className="ml-3">
                                                            {o[0]}
                                                        </span>
                                                    </label>
                                                )}
                                            />
                                    </>
                                )
                            })}
                    </div>
                </>
            );
        }))

        return permissionTable;
    }

    useEffect(() => {
        if (!rolesTable.lenght) {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try{
            const roles  = await http({ url: `/role` });
            setRoles(roles.role)
            setReady(true); 
            createRoleTable(roles.role);
        }
        catch(e)
        {
            //handle if no role has been created yet
            if (e.code == 404 || e.code == 401)
            {
                setReady(true); 
            }
            console.log("Error " + e.message.toString());           
        }
    };

    useEffect(() => {
        fetchData();
    },[reset])
    watch('name');
    
    return (
        <LoggedPage admin title={(
            <>
                <a onClick={back} className="block cursor-pointer mr-3" href="#0">
                    <svg height="24" width="24" viewBox="0 0 24 24" fill="currentcolor">
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                    </svg>
                </a>
                Manage roles
            </>
        )}>
            <Loading if={!ready}> 
                       
                        <div className="flex flex-col md:-mx-2 mb-2">
                            <div className="">
                                <label className="text-xs text-gray-500 uppercase tracking-wider block mb-2 flex flex-row items-end">
                                    <span className="flex-grow">Roles</span>
                                </label>  
                                {rolesTable}
                                
                            </div>
                        </div>

                        <div className="mt-2 mb-4 w-full">
                            <button
                                type="button"
                                className="btn btn-sm bg-lightblue-100 px-3 py-1 text-white"
                                onClick={changeShowStatus}
                            >
                                Add role
                            </button>
                        </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={formState.isSubmitting}>

                        {showNewRoleForm && <div className="mt-1 mb-4 bg-darkblue-200 text-gray-200 shadow-lg rounded-sm overflow-hidden p-4 pl-4 pb-4">
                            <div className="w-full">
                                <FormInput
                                    className="md:w-1/4 p-2"
                                    label="Role name"
                                    name='roleName'
                                    form={form}
                                    errors={errors}
                                    validation={{
                                        required: { value: true, message: 'You must enter a name for this role' }
                                    }}
                                    render={({ name, className, ref, label }) => (
                                        <input ref={ref} name={name} className={className} placeholder={label} />
                                    )}
                                />
                                <div className="pl-4 w-full flex flex-row">
                                    {generatePermissionsCheckbox(staticPermissions)}
                                </div>
                            </div>
                            <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-20">
                                Create
                            </button>
                        </div>}
                    </fieldset>
                </form>
            </Loading>
        </LoggedPage>
    );
}
export default withRouter(Roles);
