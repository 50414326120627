import React from 'react';
import { CRYPTO_CURRENCIES } from '../../../partials/currencies/cryptoCurrencies';


export default function CryptoCoinIcon(props) {
    return (
         <>
       {CRYPTO_CURRENCIES.filter(itm => itm.name == props.name ).map(({ image, name, symbol }, key) => (
            <>
                <td className="order-td">
                    <div className="flex flex-row">
                        {image()} <span className="ml-3">{symbol}</span>
                    </div>
                </td>
             </>
        ))}
       </>
    );
}