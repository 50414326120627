import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Loading from '../../partials/Loading';
import 'reactjs-popup/dist/index.css';
import './defiData.css';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Popup from 'reactjs-popup';
import { http } from '../../utils/utils';
import Logo from '../../partials/Logo';
import { PoolChart } from './poolChart'
import { colors } from './colors'


export default function DefiData() {

    const contentStyle = { background: '#000b28', 'max-width': '60rem', 'border-radius': '0.5rem', 'border-color': '#203b5a', 'width': '25rem', 'margin-right': '1rem', 'margin-left': '1rem' };

    const validator = require('validator')
    let [dateFilter, setDateFilter] = useState('1')
    const [labelSkip, setLabelSkip] = useState(1)
    const [mobileLabelSkip, setMobileLabelSkip] = useState(5)
    const [poolList, setPoolList] = useState([])
    const [defaultPool, setDefaultPool] = useState([])
    const [open, setOpen] = useState(false)
    let obj = poolList.reduce((o, key) => ({ ...o, [key]: false}), {})
    obj.aDAI = true
    obj.aUSDC = true

    let [isChanged, setChange] = useState(false);

    let [data, setData] = useState([]);
    const [poolsFilterValue, setPoolsFilterValue] = useState(obj);
    let [email] = React.useState(false)

    function groupBy(arr, criteria) {
        const newObj = arr.reduce(function (acc, currentValue) {
          if (!acc[currentValue[criteria]]) {
            acc[currentValue[criteria]] = [];
          }
          acc[currentValue[criteria]].push({ x: new Date(currentValue.timestamp).toLocaleDateString('it-IT'), y: currentValue.apy * 100 });
          return acc;
        }, {});
        return newObj;
    }

    function createGraphData(data) {
        let newObject = {}
        let newData = []
        let i = 0
        for (const [key, value] of Object.entries(data)) {
            newObject = {}
            newObject.id = key;
            newObject.data = value
            newObject.color = colors[i]
            newData.push(newObject);
            i++
        }
        return newData
    }

    const fetchData = useCallback(async () => {
        const data = await http({ url: `${process.env.REACT_APP_DATA_FETCHING_URL || 'http://localhost:8000'}/data/historical-data?pool=aDAI&pool=aUSDC&date=1` });

        const pools = await http({ url: `${process.env.REACT_APP_DATA_FETCHING_URL || 'http://localhost:8000'}/data/pools`})
        setPoolList(pools)
        setDefaultPool(pools)

        const dataByPool = groupBy(data, 'pool');

        let graphData = createGraphData(dataByPool)

        setChange(true)
        setData(graphData || []);
    }, []);


    useEffect(() => {
        fetchData()
    }, [])

    const generateQs = () => {
        setData(undefined)
        if (isChanged) {
            const qs = Object.keys(poolsFilterValue)
                .filter(key => poolsFilterValue[key] == true)
                .map(key => {
                    if (defaultPool.includes(key) && poolsFilterValue[key] == true) {
                        return `pool=${key}`
                    } 
                    
                }).join('&')
       
                if (dateFilter == 'MAX') {
                    dateFilter = ''
                }
                if (qs != '') {
                    http({ url: `${process.env.REACT_APP_DATA_FETCHING_URL || 'http://localhost:8000'}/data/historical-data?date=` + dateFilter +'&' + qs}).then((data) => {
                        const dataByPool = groupBy(data, 'pool');
    
                        let graphData = createGraphData(dataByPool)
    
                        setChange(true)
                        setData(graphData || []);
                    });

                } else {
                    setData([])
                }
        }
    }

    useEffect(() => {
        generateQs()
    }, [poolsFilterValue, dateFilter])


    
    const changeValue = (field) => {
        if (data) {
            if (data.length < 10) {
                setChange(true)
                let currentFilter = {...poolsFilterValue}
                currentFilter[field] = !poolsFilterValue[field]
                setPoolsFilterValue(currentFilter)
            } else {
                toast.error('You can\'t select more than 10 pools')
            }
        }

     }

    const removeValue = (field) => {
        setChange(true)
        let currentFilter = {...poolsFilterValue}
        currentFilter[field] = !poolsFilterValue[field]
        setPoolsFilterValue(currentFilter)
     }

    const search = (e) => {
        const newPools = defaultPool.filter(p => p.toLowerCase().startsWith(e.target.value.toLowerCase()))
        setPoolList(newPools)
    }

    const onSubmit = async () => {
        const receivedEmail = String(email)
        if (validator.isEmail(receivedEmail)) {
    
          try {
            const res = await http({
                method: 'POST',
                url: '/newsletter',
                form: {
                    email
                }
            });
    
            toast.success("Thanks for subscribing!")
          } catch (e) {
              console.error(e);
              toast.error(e?.reason || `${e}`);
          }
        } else {
            toast.error('Email not valid')
        }
      }
    

    const handleEmailChange = function (e) {
        email = e.target.value
    }

    const activeStyle = "px-4 border-b-4 border-lightblue-100 text-lightblue-100"
    const inactiveStyle = "px-4 text-gray-400 border-gray-400 border-b-4"
    
    return (
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden lg:mx-16 mx-4 md:mx-16 work-sans">
        <header className="sticky top-0 z-30 bg-darkblue-100">
            <div className="py-4 sm:py-2 lg:py-6 flex flex-row">
                <Link className="block pt-2 md:pr-16 pr-4 lg:pr-16" to="/pools">
                    <Logo className="h-4 md:h-5 mr-1" />
                </Link>
                <h3 className="hidden md:block h4">DeFi historical yields</h3>
                <h3 className="visible md:hidden text-lg mt-1 font-bold">DeFi historical yields</h3>
            </div> 
        </header>
                <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="col-span-8 hidden md:block lg:block" style={{ minHeight: "30rem", height: "52rem"}}>
                        <Loading if={!data}>
                            {isChanged && <PoolChart myData={data} skip={labelSkip}/>}
                        </Loading>
                    </div>
                    <div className="col-span-12 mx-4 md:hidden mt-2 flex flex-row gap-10">
                        <select
                            className="form-input text-sm px-12 py-2 font-bold"
                            value={dateFilter}
                            onChange={e => { setDateFilter(e.target.value); setMobileLabelSkip(e.target[e.target.selectedIndex].id) }}
                        >
                            <option value="">TIME</option>
                            <option id="5" value="1" key="1">1M</option>
                            <option id="15" value="3" key="3">3M</option>
                            <option id="30" value="YTD" key="YTD">YTD</option>
                            <option id="35" value="12" key="12">1Y</option>
                            <option id="35" value="MAX" key="24">MAX</option>
                        </select>
                        <button
                            className="form-input text-sm px-11 py-2 font-bold"
                            onClick={() => { setOpen(true) }}
                        >
                            FILTER
                        </button>
                    </div>
                    <div className="col-span-4">
                        <div className="flew flew-column">
                            <ul class="list-reset md:flex mb-4 hidden">
                                <li class={dateFilter == 1 ? activeStyle : inactiveStyle}>
                                    <a class="inline-block rounded-t py-2 px-4 text-blue-dark font-semibold cursor-pointer" onClick={() => { setDateFilter('1'); setLabelSkip(1)}}>1M</a>
                                </li>
                                <li class={dateFilter == 3 ? activeStyle : inactiveStyle}>
                                    <a class=" inline-block py-2 px-4 text-blue hover:text-blue-darker font-semibold cursor-pointer" onClick={() => { setDateFilter('3'); setLabelSkip(5) }}>3M</a>
                                </li>
                                <li class={dateFilter == 'YTD' ? activeStyle : inactiveStyle}>
                                    <a class="inline-block py-2 px-4 text-blue hover:text-blue-darker font-semibold cursor-pointer" onClick={() => { setDateFilter('YTD'); setLabelSkip(12) }}>YTD</a>
                                </li>
                                <li class={dateFilter == 12 ? activeStyle : inactiveStyle}>
                                    <a class="inline-block py-2 px-4 text-grey-light font-semibold cursor-pointer" onClick={() => { setDateFilter('12'); setLabelSkip(14) }}>1Y</a>
                                </li>
                                <li class={dateFilter == '' ? activeStyle : inactiveStyle}>
                                    <a class="inline-block py-2 px-4 text-grey-light font-semibold cursor-pointer" onClick={() => { setDateFilter(''); setLabelSkip(14) }}>MAX</a>
                                </li>
                            </ul>                       
                            <div className="p-5 border border-blue-bridge hidden md:block">
                                <div>
                                    <div>
                                        <div className="grid grid-cols-1 md:grid-cols-4 gap-5 ">
                                            <label className="col-span-2 text-white border-b-4 border-grey-bridge text-xl uppercase font-bold tracking-wider mb-2 flex flex-row items-end">
                                                <span className="flex-grow">Pools</span>
                                            </label>
                                            <label className="col-span-2 text-white text-xl uppercase font-bold tracking-wider mb-2 flex flex-row items-end">
                                                <input class="border rounded w-full py-2 px-3 text-lightblue-100 leading-tight blue-bridge" id="search" type="text" placeholder="Search" onChange={(e) => { search(e)}}/>
                                            </label>
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-4 h-80 overflow-y-scroll">
                                            <div className="col-span-4 mb-1 mt-1">
                                                {poolList.map((p, index) => {
                                                    return (
                                                        <div className="mb-1 py-2 flex flew-row border-b border-blue-bridge">
                                                            <span className="flex-grow text-white font-bold text-sm pl-2">{p}</span>                                                                                                              
                                                            <label className="flex items-center mr-2">
                                                            <input onClick={() => { !poolsFilterValue[p] ? changeValue(p) : removeValue(p)}} className="form-checkbox" type="checkbox" checked={poolsFilterValue[p] == true ? true : false}/>
                                                            </label>                                                           
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Popup open={open} position="center center" keepTooltipInside={false} onClose={() => setOpen(false)} {...{ contentStyle}}>
                                <div>
                                    <div>
                                        <div className="grid grid-cols-1 md:grid-cols-4 pt-2 pl-2">                                  
                                            <img className="w-4 top-0 fill-current text-white" src={require('../../images/cross.svg')} width="120" height="120" alt="Onboarding" onClick={() => setOpen(false)}/>                                                                  
                                        </div>                
                                        <div className="grid grid-cols-1 md:grid-cols-4 gap-5 p-5">
                                            <label className="col-span-2 text-white border-b-4 border-grey-bridge text-xl uppercase font-bold tracking-wider mb-2 flex flex-row items-end">
                                                <span className="flex-grow pb-2 md:pb-0">Pools</span>
                                            </label>
                                            <label className="col-span-2 text-white text-xl uppercase font-bold tracking-wider mb-2 flex flex-row items-end">
                                                <input class="border rounded w-full py-2 px-3 text-lightblue-100 leading-tight blue-bridge" id="search" type="text" placeholder="Search" onChange={(e) => { search(e)}}/>
                                            </label>
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-4 h-80 overflow-y-scroll">
                                            <div className="col-span-4 mb-1 mt-1">
                                                {poolList.map((p, index) => {
                                                    return (
                                                        <div className="mb-1 py-2 flex flew-row border-b border-blue-bridge">
                                                            <span className="flex-grow text-white font-bold text-sm pl-2">{p}</span>                                                                                                              
                                                            <label className="flex items-center mr-2">
                                                            <input onClick={() => { !poolsFilterValue[p] ? changeValue(p) : removeValue(p)}} className="form-checkbox" type="checkbox" checked={poolsFilterValue[p] == true ? true : false}/>
                                                            </label>                                                           
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                            <div className="col-span-12 block md:hidden mb-2" style={{ height: "40rem", width: "30rem" }}>
                                <Loading if={!data}>
                                    {isChanged && <PoolChart myData={data} skip={mobileLabelSkip}/>}
                                </Loading>
                            </div>
                            <div className="p-5 border border-blue-bridge">
                                <div>
                                    <div>
                                        <div className="grid grid-cols-1 md:grid-cols-3">
                                            {data && data.map((p, index) => {
                                                return (
                                                    <div className="mb-1 md:mb-5 py-3 flex flew-row">
                                                        <div className="" style={{backgroundColor: p.color, height: '1rem', width: '1rem'}}></div>
                                                        <span className="flex-grow text-white font-bold text-sm pl-2">{p.id}</span>                                                                                                                               
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                    </div>
                </div>
                <div className="hidden md:grid grid-cols-1 md:grid-cols-12 mt-16 mb-8">
                    <div className="col-span-12 p-5 border border-blue-bridge">
                        <div className="grid grid-cols-1 md:grid-cols-12 p-8">
                            <div className="col-span-12 md:col-span-6 flex flex-row">
                                <img className="mb-4 w-32 mr-4 top-0" src={require('../../images/email.svg')} width="120" height="120" alt="Onboarding" />
                                <div class="flex flex-col" id="contact-form">
                                    <p className="text-2xl mb-2 font-extrabold">
                                    Want to receive news about
                                    <strong> BRIDGE</strong> and DeFi?<br></br> Subscribe to our newsletter:
                                    </p>
                                    <div className="text-xs flex flex-row w-full md:text-sm sm:flex-row mx-auto">   
                                        <input
                                            type="email"
                                            onChange={handleEmailChange}
                                            className="form-input w-8/12 md:w-8/12 mb-2 sm:mb-0 mr-2"
                                            placeholder="Your e-mail"
                                            aria-label="Email address"
                                        />
                                        <a className="md:w-4/12 w-4/12 sm:w-3/12 md:text-sm text-lightblue-100 hover:text-gray-200 px-4 py-3 flex items-center justify-center rounded border border-lightblue sm:text-xs" 
                                            onClick={onSubmit} href="#0" data-aos="fade-left">
                                            Get in touch
                                        </a>
                                    </div>                            
                                </div>
                            </div>
                            <div className="col-span-1"></div>
                            <div className="col-span-5 flex flex-row">
                                <img className="mb-4 w-32 mr-8 top-0" src={require('../../images/review.svg')} width="120" height="120" alt="Onboarding" />
                                <div class="flex flex-col">
                                    <p className="text-2xl mb-2 font-extrabold">
                                    Any feedback? <br></br>
                                    Reach us on our social media:
                                    </p>
                                    <div className="text-xs flex flex-row w-full md:text-sm sm:flex-row mx-auto mt-4 ml-4">   
                                        <a href="https://www.linkedin.com/company/bridge-defi-s-r-l/">
                                            <img className="mb-4 w-16 mr-16 top-0" src={require('../../images/LinkedIn.svg')} width="120" height="120" alt="Onboarding" />
                                        </a>
                                        <a href="https://twitter.com/BRIDGE_Defi"> 
                                            <img className="mb-4 w-16 mt-4 mr-16 top-0" src={require('../../images/Twitter.svg')} width="120" height="120" alt="Onboarding" />
                                        </a>
                                        <a href="https://discord.com/channels/900376769893380107/900376770342166550">
                                            <img className="mb-4 mt-4  w-16 top-0" src={require('../../images/Discord.svg')} width="120" height="120" alt="Onboarding" />
                                        </a>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid md:hidden grid-cols-12 p-2">
                    <div className="col-span-3"></div>                        
                    <img className="mb-4 col-span-6" src={require('../../images/email.svg')} width="auto" height="120" alt="Onboarding" style={{ alignSelf: 'center' }}/>
                    <div className="col-span-3"></div>                        

                    <div className="col-span-12">
                        <div class="flex flex-col text-center" id="contact-form">
                            <p className="text-xl mb-2 font-extrabold">
                            Want to receive news about
                            <strong> BRIDGE</strong> and DeFi?<br></br> Subscribe to our newsletter:
                            </p>
                            <div className="text-xs flex flex-row w-full md:text-sm sm:flex-row mx-auto mt-2">   
                                <input
                                    type="email"
                                    onChange={handleEmailChange}
                                    className="text-xs form-input w-8/12 mr-1"
                                    placeholder="Your e-mail"
                                    aria-label="Email address"
                                />
                                <a className="w-4/12 text-xs text-lightblue-100 hover:text-gray-200 px-4 py-1 flex items-center justify-center rounded border border-lightblue" 
                                    onClick={onSubmit} href="#0" data-aos="fade-left">
                                    Get in touch
                                </a>
                            </div>                            
                        </div>
                    </div>
                    <div className="col-span-3 mt-4"></div>                        
                    <img className="mb-4 col-span-6 mt-8" src={require('../../images/review.svg')} width="auto" height="120" alt="Onboarding" style={{ alignSelf: 'center' }}/>
                    <div className="col-span-3"></div>                        

                    <div className="col-span-12">
                        <div class="flex flex-col text-center" id="contact-form">
                            <p className="text-xl mb-2 font-extrabold">
                            Any feedback? <br></br>
                            Reach us on our social media:
                            </p>
                            <div className="text-xs flex flex-row w-full md:text-sm sm:flex-row mx-8 mt-4">   
                                <a href="https://www.linkedin.com/company/bridge-defi-s-r-l/">
                                    <img className="mb-4 w-10 mr-16 top-0" src={require('../../images/LinkedIn.svg')} width="120" height="120" alt="Onboarding" />
                                </a>
                                <a href="https://twitter.com/BRIDGE_Defi"> 
                                    <img className="mb-4 w-10  mt-2 mr-16 top-0" src={require('../../images/Twitter.svg')} width="120" height="120" alt="Onboarding" />
                                </a>
                                <a href="https://discord.gg/vMnbgPB5Pu">
                                    <img className="mb-4  w-10 mt-2 top-0" src={require('../../images/Discord.svg')} width="120" height="120" alt="Onboarding" />
                                </a>
                            </div>                            
                        </div>
                    </div>
                </div>
        </div>                                
    );

}