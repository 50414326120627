import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Loading from '../../partials/Loading';
import Table from '../../partials/Table/Table';
import 'reactjs-popup/dist/index.css';
import TableBody from '../../partials/Table/TableBody';
import TableCell from '../../partials/Table/TableCell';
import TableRow from '../../partials/Table/TableRow';
import Linkify from '../../utils/Linkify';
import SidePanel from '../../utils/SidePanel';
import { UserContext } from '../../utils/UserProvider';
import { http } from '../../utils/utils';
import InvestForm from './InvestForm';
import WithdrawForm from './WithdrawForm';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import moment from 'moment';
import OrderTable from './OrderPlan/orderTable'
import { CoinList } from './CoinList';
import Popup from 'reactjs-popup';
import { Web3Context } from '../../utils/Web3Provider';


export default function PoolList() {
    let [pools, setPools] = useState();
    const [rates, setRates] = useState();
    const [steps, setSteps] = useState([]);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const userContext = useContext(UserContext);
    const [investPopupVisible, setInvestPopupVisible] = useState(false);
    const [withdrawPopupVisible, setWithdrawPopupVisible] = useState(false);
    const user = userContext?.me || {};
    const verified = user.verification
        && user.verification.personalInfo
        && user.verification.residenceAddress
        && user.verification.settlementAddress
        && user.verification.document
        && user.verification.document.documentType
        && user.verification.document.front
        && user.verification.document.retro
        && user.verification.document.profilePic;

    const isAdmin = userContext.me?.isAdmin;
    const [selectedPool, setSelectedPool] = useState();
    const [visibleRows, setVisibleRows] = useState([]);
    const xStepWidth = 1;

    const closeModal = (popup) => {
        (popup == 'invest') ? setInvestPopupVisible(false) : setWithdrawPopupVisible(false);
    };
    const contentStyle = { background: '#000b28', 'min-width': '80rem', 'max-width': '90rem', 'border-radius': '0.5rem', 'border-color': '#203b5a', 'width': 'auto' };
    const orderPlanStyle = { background: '#000b28', 'min-width': '50rem', 'max-width': '90rem', 'border-radius': '0.5rem', 'border-color': '#203b5a', 'width': 'auto' };

    const changeOpenStatus = async () => {
        setOpen(!open);
    }

    const {
        address
    } = useContext(Web3Context);



    const [bridgeTokenTvls, setBridgeTokenTvls] = useState([]);

    const fetchPools = useCallback(async () => {
        const pools = await http({ url: '/pool' });
        
        let apy = await getApy(); //array
        
        pools[0].apyYearly = apy[0];
        pools[0].apyMonthly = apy[1];
        pools[1].apyYearly = apy[2];
        pools[1].apyMonthly = apy[3];

        setPools(pools || []);
    }, []);

    const getApy = async () => {
        const bridgeUrls = ["https://data.enzyme.finance/api/fund/metrics/range?address=0x8ee084b76651d7fa501b175f3ac6585bdead04fc&range=1y",
                            "https://data.enzyme.finance/api/fund/metrics/range?address=0x8ee084b76651d7fa501b175f3ac6585bdead04fc&range=1m",
                            "https://data.enzyme.finance/api/fund/metrics/range?address=0x1bf219a33bda5742a12b4d4513db687895629146&range=1y",
                            "https://data.enzyme.finance/api/fund/metrics/range?address=0x1bf219a33bda5742a12b4d4513db687895629146&range=1m"]
        let apy = [];

        for (let i = 0; i < bridgeUrls.length; i++) {
            const response = await fetch(bridgeUrls[i]);

            const res = await response.json();
            let latestSharedPrice = res['data'][res['data'].length - 1]['calculations']['DENOMINATION']['price'];
            let firstSharedPrice = res['data'][0]['calculations']['DENOMINATION']['price'];

            apy[i] = (((latestSharedPrice - firstSharedPrice) / firstSharedPrice).toFixed(2)) * 100;
        }
        return apy;
    }

    useEffect(() => {
        let isMounted = true;
        const getRates = async () => {
            let rates = {
                firstDates: [],
                sharedPrice: [
                    [

                    ],
                    [

                    ]
                ]
            };
            const bridgeUrls = ["https://data.enzyme.finance/api/fund/metrics/range?address=0x8ee084b76651d7fa501b175f3ac6585bdead04fc&range=1m", "https://data.enzyme.finance/api/fund/metrics/range?address=0x1bf219a33bda5742a12b4d4513db687895629146&range=1m"]
            
            for (let i = 0; i < bridgeUrls.length; i++) {
                const response = await fetch(bridgeUrls[i]);
        
                const res = await response.json();
                for (let j = 0; j < res['data'].length; j++) {
                    let ms = res['data'][j]['timestamp'] * 1000;

                    let date = moment(ms).format('YYYY-MM-DD');
                    rates.sharedPrice[i][j] = { 
                        date, 
                        value: res['data'][j]['calculations']['DENOMINATION']['price']
                    }
                }
            }
            rates.firstDates = [rates.sharedPrice[0][0].date, rates.sharedPrice[1][0].date];
        
            if (isMounted) {
                setRates(rates);
            }   setChart(rates);
        }
        setPools(pools);
        getRates();
        
    }, []);

    const setChart = (rates) =>
    {   
         let allSteps = [];
                for(let j = 0; j <2; j++)
                {

                    const ratesData = rates.sharedPrice[j].map(point => (point.value))
                    let  maxPoint = (parseFloat(Math.max(...ratesData).toFixed(1)) + 0.2).toFixed(1);
                    let  minPoint = (parseFloat(Math.min(...ratesData).toFixed(1)) + 0.2).toFixed(1);
                   
                    const step = ((maxPoint - minPoint) / 14 + 0.01).toFixed(2);
                    const mean = (ratesData.reduce((a,b) => a + b, 0) / ratesData.length).toFixed(2);
                
                    maxPoint = (parseFloat(mean) + 7 * parseFloat(step)).toFixed(2);
                    minPoint = (parseFloat(mean) - 8 * parseFloat(step)).toFixed(2);

                    const stepsArr = [];
                    stepsArr[0] = parseFloat(minPoint);

                    for(let i = 1; i <15; i++)
                    {
                        stepsArr[i] = (parseFloat(stepsArr[i-1]) + parseFloat(step)).toFixed(2);
                    }
                    allSteps[j] = stepsArr;

                }
                setSteps(allSteps);
    }

    const onPoolClick = (pool) => () => {
        if (isAdmin) {
            history.push(`/pools/${pool._id}`);
        } else {
            const index = visibleRows.indexOf(pool._id);
            const isVisible = index >= 0;
            if (isVisible) {
                visibleRows.splice(index, 1);
            } else {
                visibleRows.push(pool._id);
            }
            setVisibleRows(visibleRows.slice());
        }
    };
    const openInvestPopup = (pool) => e => {
        e.stopPropagation();
        setSelectedPool(pool);
        setInvestPopupVisible(true);
    };

    const openWithdrawPopup = (pool) => e => {
        e.stopPropagation();
        setSelectedPool(pool);
        setWithdrawPopupVisible(true);
    };

    useEffect(() => {
        fetchPools();
    }, [fetchPools]);

    useEffect(() => {

    const fetchTokenPrices = async () => {
            let tvlAll = [];

            const bridgeUrls = ["https://data.enzyme.finance/api/fund/metrics/current?address=0x1bf219a33bda5742a12b4d4513db687895629146", "https://data.enzyme.finance/api/fund/metrics/current?address=0x8ee084b76651d7fa501b175f3ac6585bdead04fc"]
            
            for(let i = 0; i < bridgeUrls.length; i++)
            {
                const response = await fetch(bridgeUrls[i]);
                // Storing data in form of JSON
                const data = await response.json();
                const price = data['data']['calculations']['DENOMINATION']['price'];
                const totalsupply = data['data']['shares']['totalSupply'];

                tvlAll[i] = (price * totalsupply).toFixed(2);
            }
            setBridgeTokenTvls(tvlAll.reverse());
        };
        fetchTokenPrices(); 

    }, [fetchPools]);
    
    return (
        <Loading if={!pools}>
            <Table>
                <TableBody>
                    {(!pools?.length) && (
                        <TableRow>
                            <TableCell colSpan="4">
                                <i>No results</i>
                            </TableCell>
                        </TableRow>
                    )}
                    {user.enabled && pools && pools.map((pool, num) => (
                        <Fragment key={pool._id}>
                            <TableRow className="cursor-pointer hover:bg-lightblue-400 font-bold" onClick={onPoolClick(pool)}>
                                <TableCell>
                                    <span className="text-base">{pool.name}</span>
                                    <div className={`text-xs ${pool.active ? 'text-green-500' : 'text-red-500'}`}>
                                        {isAdmin
                                            ? pool.active ? 'active' : 'not active'
                                            : ''}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <span className="uppercase">APY Yearly</span>
                                    <span className="text-lightblue-100 ml-3">{(pool.apyYearly).toFixed(2)} %</span>
                                </TableCell>
                                <TableCell>
                                    <span className="uppercase">APY Monthly</span>
                                    <span className="text-lightblue-100 ml-3">{(pool.apyMonthly).toFixed(2)} %</span>
                                </TableCell>
                                {isAdmin && (
                                    <TableCell>
                                        <span className="uppercase">Available</span>
                                        <span className="text-lightblue-100 ml-3">{pool.balanceAvailable}</span>
                                    </TableCell>
                                )}
                                <TableCell>
                                    <span className="uppercase">TVL</span>
                                    <span className="text-lightblue-100 ml-3">{(bridgeTokenTvls[num])} $</span>
                                </TableCell>
                                <TableCell className="whitespace-nowrap w-px">
                                    <button
                                        className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 py-1 px-3"
                                        onClick={openInvestPopup(pool)}
                                    >
                                        Invest
                                    </button>
                                </TableCell>
                                <TableCell className="whitespace-nowrap w-px">
                                    <button
                                        className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 py-1 px-3"
                                        onClick={openWithdrawPopup(pool)}
                                    >
                                        Withdraw
                                    </button>
                                </TableCell>
                            </TableRow>
                            {visibleRows.indexOf(pool._id) >= 0 && (
                                <TableRow className="bg-darkblue-100">
                                    <TableCell colSpan="6">
                                        <div
                                            className="grid grid-cols-1 md:grid-cols-12 gap-5 whitespace-normal break-words mb-2"
                                            style={{ lineHeight: 1.4 }}
                                        >
                                            <div className="col-span-3">
                                                <div className="tracking-wide uppercase text-white mb-2 font-medium">Description</div>
                                                <div className="overflow-hidden whitespace-normal text-darkblue-400">
                                                    <Linkify>
                                                        {pool.description.protocolDescription}
                                                    </Linkify>
                                                </div>
                                            </div>
                                            <div className="col-span-3">
                                                <div className="tracking-wide uppercase text-white mb-2 font-medium">Features</div>
                                                <ul>
                                                    {(pool.description.features || []).map((feature, index) => (
                                                        <li key={index} className="text-darkblue-400 mb-1">
                                                            <CoinList content={feature}>
                                                                <Linkify className="text-lightblue-100">
                                                                    {feature}
                                                                </Linkify>
                                                            </CoinList>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="col-span-6 row-span-3 md:pt-4" style={{ maxHeight: 450 }}>
                                                <ResponsiveLine
                                                    data={[
                                                        {
                                                            id: 'data',
                                                            data: rates.sharedPrice[num].map(point => ({
                                                                x: point.date,
                                                                y: point.value
                                                            }))
                                                        }
                                                    ]}
                                                    margin={{ top: 5, left: 55, right: 5, bottom: 70 }}
                                                    xScale={{
                                                        type: 'time',
                                                        min: rates.firstDates[num],
                                                        format: '%Y-%m-%d',
                                                        precision: 'day',
                                                    }}
                                                    yScale={{ type: 'linear', min: steps[num][0], max: steps[num][steps[num].length - 1]}}
                                                    enableArea
                                                    areaOpacity={1}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisLeft={{
                                                        orient: 'left',
                                                        format: value => value ? `${value}` : '',
                                                        tickValues: 10,
                                                        tickSize: 0,
                                                        tickPadding: 10,
                                                        tickRotation: 0,
                                                        legend: 'share price',
                                                        legendOffset: -50,
                                                        legendPosition: 'middle'
                                                    }}
                                                    axisBottom={{
                                                        orient: 'bottom',
                                                        format: value => {
                                                            const date = moment(value);
                                                            return date.isBefore(rates.firstDates[num]) ? '' : date.format('D MMM').toLowerCase();
                                                        },
                                                        tickValues: rates.sharedPrice[num]
                                                            .filter((v, index) => index % xStepWidth === 0)
                                                            .map(point => new Date(point.date)),
                                                        tickSize: 0,
                                                        tickPadding: 10,
                                                        tickRotation: -90,
                                                        legend: 'days',
                                                        legendOffset: 60,
                                                        legendPosition: 'middle',
                                                    }}
                                                    areaBaselineValue = {0.9}
                                                    pointSize = {30}
                                                    enableGridX={true}
                                                    enableGridY={true}
                                                    gridYValues={steps[num]}
                                                    gridXValues={rates.sharedPrice[num]
                                                        .filter((v, index) => index % xStepWidth === 0)
                                                        .map(point => new Date(point.date))}
                                                    pointSymbol={() => <rect x="-2" y="-2" width="5" height="5" fill="#fff" />}
                                                    colors="#a8b0c6"
                                                    theme={{
                                                        textColor: '#fff',
                                                        axis: {
                                                            domain: {
                                                                line: {
                                                                    stroke: '#fff',
                                                                    strokeWidth: 1,
                                                                }
                                                            }
                                                        },
                                                        grid: {
                                                            line: {
                                                                stroke: '#203a59',
                                                                strokeWidth: 1,
                                                                strokeDasharray: '1 1',
                                                            }
                                                        }
                                                    }}
                                                    defs={[
                                                        linearGradientDef('gradientA', [
                                                            { offset: 0, color: '#a8b0c6', opacity: 0.8 },
                                                            { offset: 50, color: '#a8b0c6', opacity: 0.5 },
                                                            { offset: 80, color: '#a8b0c6', opacity: 0.1 },
                                                        ]),
                                                    ]}
                                                    fill={[{ match: '*', id: 'gradientA' }]}
                                                />
                                            </div>
                                            <div className="col-span-3 row-span-2">
                                                <div className="tracking-wide uppercase text-white mb-2 font-medium">Details</div>
                                                <ul>
                                                    {(pool.description.details || []).map((detail, index) => (
                                                        <li key={index} className="text-darkblue-400 mb-2">
                                                            <div className="text-white font-medium mb-0">{detail.title}</div>
                                                            <div>{detail.data}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="col-span-3">
                                                <div className="tracking-wide uppercase text-white mb-2 font-medium">Sources of profit</div>
                                                <ul>
                                                    {(pool.description.sourceOfProfit || []).map((source, index) => (
                                                        <li key={index} className="text-darkblue-400 mb-1">
                                                            <Linkify>
                                                                {source}
                                                            </Linkify>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Fragment>
                    ))}
                </TableBody>
            </Table>

            {selectedPool && investPopupVisible && <Popup open='true' position="center center"  keepTooltipInside={false} onClose={() => closeModal('invest')} {...{contentStyle}}>
                <InvestForm pool={selectedPool} />
            </Popup>}
            {selectedPool && withdrawPopupVisible && <Popup open='true' position="center center"  keepTooltipInside={false} onClose={() => closeModal('withdraw')} {...{contentStyle}}>
                <WithdrawForm pool={selectedPool} changeOpenStatus={changeOpenStatus}/>
            </Popup>}
            {selectedPool && <Popup open={open} position="center center" keepTooltipInside={false} onClose={() => setOpen(false)} {...{ contentStyle: orderPlanStyle}}>
                <div class="dark flex-grow text-center p-5">
                    <OrderTable poolName ={selectedPool.name} address={address}/>
                </div>
            </Popup>}                                          
        </Loading>
    );

}