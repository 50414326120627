import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Header from '../partials/Header/Header';
import { FormInput } from '../utils/FormInput';
import { EMAIL_REGEX, http } from '../utils/utils';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom";
import Body from '../partials/Body';
import user from '../utils/user';

function ForgotPassword(props) {
    const form = useForm();
    const { formState, errors, handleSubmit } = form;
    const history = useHistory();
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const userId = new URLSearchParams(search).get('id');


    const onSubmit = async ({ email }) => {
        try {
            await http({
                method: 'POST',
                url: '/users/reset-password-email',
                form: { email }
            });
        } catch (e) {
            console.error(e);
        }
        toast.success('We have e-mailed your password reset link');
        history.push('/signin');
    };

    const resetPassword = async ({ password }) => {
        try {
            const res = await http({
                method: 'POST',
                url: '/users/reset-password',
                form: { password, token, userId }
            });
            toast.success(res.message);
            history.push('/signin');

        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || `${JSON.stringify(e.reason.message)}`);
        }
        
    };

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <Body className="flex-grow">
                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                            <div className="max-w-sm mx-auto">
                                {!token && 
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <fieldset disabled={formState.isSubmitting}>
                                            <div className="max-w-3xl mx-auto pb-10 md:pb-15">
                                                <h1 className="h4 mb-5">
                                                    Forgot your password?
                                                </h1>
                                                <p>
                                                    Enter the e-mail address associated to your account
                                                    and we'll send you a link to reset your password.
                                                </p>
                                            </div>

                                            <FormInput
                                                label="E-mail"
                                                name="email"
                                                form={form}
                                                errors={errors}
                                                validation={{
                                                    required: true,
                                                    pattern: EMAIL_REGEX
                                                }}
                                                render={({ name, className, ref, label }) => (
                                                    <input
                                                        ref={ref}
                                                        name={name}
                                                        className={className}
                                                        placeholder={label}
                                                        type="email"
                                                        autoFocus
                                                    />
                                                )}
                                            />

                                            <div className="flex flex-wrap mt-6">
                                                <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 w-full">
                                                    Continue
                                                </button>
                                            </div>
                                        </fieldset>
                                    </form>
                                }
                                {token && 
                                    <form onSubmit={handleSubmit(resetPassword)}>
                                        <fieldset disabled={formState.isSubmitting}>
                                            <div className="max-w-3xl mx-auto pb-10 md:pb-15">
                                                <h1 className="h4 mb-5">
                                                    Password Reset
                                                </h1>
                                                <p>
                                                    Enter a new password
                                                </p>
                                            </div>

                                            <FormInput
                                                label="Password"
                                                name="password"
                                                form={form}
                                                errors={errors}
                                                validation={{
                                                    required: { value: true, message: 'You must enter a password' },
                                                    minLength: { value: 6, message: 'Password must be at least 6 characters' }
                                                }}
                                                render={({ name, className, ref, label }) => (
                                                    <input
                                                        ref={ref}
                                                        name={name}
                                                        className={className}
                                                        placeholder={label}
                                                        type="password"
                                                    />
                                                )}
                                            />

                                            <div className="flex flex-wrap mt-6">
                                                <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 w-full">
                                                    Continue
                                                </button>
                                            </div>
                                        </fieldset>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </Body>
        </div>
    );
}

export default withRouter(ForgotPassword);
