import React from 'react';

export default function LogoAUSDC(props) {
    return (
        <svg viewBox="0 0 256 256" width={props.width} height={props.height} className={props.className}>
            <defs>
                <linearGradient id="prefix__linear-gradient" x1=".843" x2=".206" y1=".135" y2=".886" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#b6509e" />
                    <stop offset="1" stop-color="#2ebac6" />
                </linearGradient>
            </defs>
            <g transform="translate(-20 -20)">
                <path fill="url(#prefix__linear-gradient)" d="M128 256a128.976 128.976 0 01-25.8-2.6 127.309 127.309 0 01-45.77-19.261 128.366 128.366 0 01-46.375-56.315A127.357 127.357 0 012.6 153.8a129.251 129.251 0 010-51.593 127.31 127.31 0 0119.26-45.77 128.372 128.372 0 0156.317-46.378A127.33 127.33 0 01102.2 2.6a129.244 129.244 0 0151.593 0 127.308 127.308 0 0145.77 19.26 128.367 128.367 0 0146.375 56.316A127.343 127.343 0 01253.4 102.2a129.248 129.248 0 010 51.593 127.3 127.3 0 01-19.26 45.77 128.382 128.382 0 01-56.316 46.375A127.4 127.4 0 01153.8 253.4 128.977 128.977 0 01128 256zm0-242.287a115.145 115.145 0 00-23.033 2.322A113.657 113.657 0 0064.1 33.232a114.622 114.622 0 00-41.4 50.283 113.7 113.7 0 00-6.659 21.452 115.4 115.4 0 000 46.065 113.66 113.66 0 0017.2 40.866 114.627 114.627 0 0050.282 41.407 113.75 113.75 0 0021.453 6.658 115.381 115.381 0 0046.065 0 113.609 113.609 0 0040.866-17.2 114.622 114.622 0 0041.393-50.278 113.741 113.741 0 006.659-21.453 115.4 115.4 0 000-46.065 113.662 113.662 0 00-17.2-40.865A114.619 114.619 0 00172.485 22.7a113.74 113.74 0 00-21.453-6.659A115.145 115.145 0 00128 13.714z" transform="translate(20 20)" />
                <g transform="translate(49.714 49.714)">
                    <path id="prefix__Path_7579" fill="#2775ca" d="M98.285 196.571A98.285 98.285 0 100 98.285a98.049 98.049 0 0098.285 98.286z" />
                    <path id="prefix__Path_7580" d="M771.546 572.215c0-14.333-8.6-19.247-25.8-21.294C733.46 549.283 731 546.007 731 540.273s4.1-9.419 12.286-9.419c7.371 0 11.467 2.457 13.514 8.6a3.08 3.08 0 002.867 2.047h6.552a2.8 2.8 0 002.867-2.866v-.41a20.459 20.459 0 00-18.429-16.79v-9.828c0-1.638-1.229-2.867-3.276-3.277h-6.143c-1.638 0-2.867 1.229-3.277 3.277v9.419c-12.286 1.638-20.066 9.829-20.066 20.067 0 13.514 8.19 18.837 25.39 20.886 11.467 2.047 15.153 4.5 15.153 11.057s-5.734 11.057-13.514 11.057c-10.648 0-14.334-4.505-15.562-10.648a2.991 2.991 0 00-2.866-2.457h-6.963a2.8 2.8 0 00-2.866 2.867v.41c1.637 10.237 8.19 17.609 21.7 19.657v9.829c0 1.637 1.229 2.866 3.276 3.276h6.143c1.638 0 2.867-1.229 3.277-3.276v-9.829c12.286-2.048 20.476-10.648 20.476-21.705z" fill="#fff" transform="translate(-646.232 -458.369)" />
                    <path id="prefix__Path_7581" d="M300.994 419.85a61.281 61.281 0 010-115.075 4.111 4.111 0 002.457-4.1v-5.733a3.113 3.113 0 00-2.457-3.276 2.466 2.466 0 00-1.638.409 73.694 73.694 0 000 140.466 2.49 2.49 0 003.686-1.638c.41-.409.41-.819.41-1.637v-5.734c-.001-1.225-1.23-2.863-2.458-3.682zM344.4 292.079a2.49 2.49 0 00-3.686 1.638c-.41.41-.41.819-.41 1.638v5.733a5.432 5.432 0 002.457 4.1 61.281 61.281 0 010 115.075 4.111 4.111 0 00-2.457 4.1v5.733a3.113 3.113 0 002.457 3.276 2.466 2.466 0 001.638-.409 74.01 74.01 0 000-140.876z" fill="#fff" transform="translate(-223.594 -263.003)" />
                </g>
            </g>
        </svg>
    );
}