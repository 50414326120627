import { idleContractData } from './assetList'
import Web3 from 'web3';

let web3;

if (window.web3) {
  web3 = new Web3(window.web3.currentProvider);
}

export const mintIdle = async(signerAddress, amount) =>
{
	//Withdraw idle
	const idleContract = new web3.eth.Contract(idleContractData.ABI, idleContractData.address);
											
	const tx = await idleContract.methods.mintIdleToken(amount, false, signerAddress).send({ from: signerAddress });

}

