import React from 'react';
import { Link } from 'react-router-dom';

export default function NonLoggedUserMenu() {
    return (
        <>  
            <li>
                <Link
                        to="/defi-data"
                        className="font-small text-gray-400 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                    >
                        Historical Yields
                </Link>
            </li>
            <li>
                <a
                        href="https://medium.com/@BRIDGE_DeFi"
                        className="mr-4 font-small text-gray-400 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                    >
                        Blog
                </a>
            </li>
            <li>
                <Link
                    to="/signin"
                    className="font-small text-lightblue-100 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out rounded border border-lightblue"
                >
                    Sign in
            </Link>
            </li>
            
        </>
    );
}