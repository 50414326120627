import React from "react";
import { ResponsivePie } from "@nivo/pie";

export const MyResponsivePieCanvas = ({ data, colorsArray}) => (
  <ResponsivePie
    data={data.map((item, index) => {
      return {
        color: colorsArray[index],
        id: item.id,
        label: item.label,
        value: item.value
      };
    })}
    margin={{ top: 10, right: 20, bottom: 100, left: 20 }}
    padAngle={0}
    cornerRadius={0}
    colors={{ datum: 'data.color' }}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    legends={[
      {
          "anchor": "bottom-left",
          "direction": "column",
          "translateY": 110,
          "translateX": 0,
          "itemWidth": 80,
          "itemHeight": 40.2,
          "symbolSize": 12,
          "symbolShape": "square",
          "itemTextColor": '#ffffff'
      }
    ]}
    enableArcLabels={false}
    enableArcLinkLabels={false}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    sortByValue={true}
    
  />
);

