import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useParams, withRouter} from 'react-router-dom';
import LoggedPage from '../../partials/LoggedPage';
import Loading from '../../partials/Loading';
import { FormInput } from '../../utils/FormInput';
import { http } from '../../utils/utils';


function AdvEditQuestions() {
 
    const form = useForm({ defaultValues: { active: true } });
    const { formState, handleSubmit, errors, getValues, reset, watch } = form;
    const [numQuestions, setNumQuestions] = useState(1);
    const history = useHistory();
    const [ready, setReady] = useState(false);


    const onSubmit = async (formData) => {
        try {
            const formattedData = Object.keys(formData).filter(key => key.indexOf('question') === 0).map(name => {
                            const index = name.replace('question', '');
                            return {
                                description: formData[`question${index}`],
                                options: formData[`answer${index}`].split(';'),
                                type: formData[`type${index}`]
                            };
                        })
            const questionsObject = {"questions": formattedData};

            
            await http({
                method: 'POST',
                url: '/advisory',
                form: questionsObject
            });
            toast.success('Advisory Updated');
        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || `${e}`);
        }
    };


    const fetchData = async () => {
        try{
            const questions  = await http({ url: `/advisory` });
            setNumQuestions(questions?.questions?.length || 1);
            const formData = {};
            (questions.questions || []).forEach((question, index) => {
                formData[`question${index}`] = question.description;
                formData[`answer${index}`] = question.options.join(';');
                formData[`type${index}`] = question.type;
            });
            reset(formData);
            setReady(true); 
        }
        catch(e)
        {
            if (e.code == 404)
            {
                setNumQuestions(1);
                setReady(true); 
            }
            console.log("Error " + e.message.toString());           
        }
    };

    useEffect(() => {
        fetchData();
    },[reset])
    watch('name');
    
    return (

            <Loading if={!ready}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={formState.isSubmitting}>
                       

                        <div className="flex flex-col md:-mx-2 mb-2 mt-2">
                            <div className="">
                                <label className="text-xs text-gray-500 uppercase tracking-wider block mb-2 flex flex-row items-end">
                                    <span className="flex-grow">Questions</span>
                                    <button
                                        type="button"
                                        className="float-right btn btn-sm bg-lightblue-100 px-3 py-1 text-white"
                                        onClick={() => setNumQuestions(numQuestions + 1)}
                                    >
                                        Add question
                                    </button>
                                </label>
                                {numQuestions > 0 && Array.from({ length: numQuestions }).map((v, index) => (
                                    <div key={index} className="mb-4 border border-gray-500 rounded px-4 py-2">
                                        <div className="flex flex-col md:flex-row md:-mx-2">
                                            <FormInput
                                                className="md:w-2/5 md:mx-2"
                                                label="Question"
                                                name={`question${index}`}
                                                form={form}
                                                errors={errors}
                                                validation={{
                                                    required: { value: true, message: 'You must enter a description' },
                                                }}
                                                render={({ name, className, ref, label }) => (
                                                    <input ref={ref} name={name} className={className} placeholder={label} />
                                                )}
                                            />
                                           
                                            <FormInput
                                                className="md:w-2/5 md:mx-2"
                                                label="Options (separated by ; )"
                                                name={`answer${index}`}
                                                form={form}
                                                errors={errors}
                                                validation={{
                                                    required: { value: true, message: 'You must enter an option' },
                                                }}
                                                render={({ name, className, ref, label }) => (

                                                    <input ref={ref} name={name} className={className} placeholder={label} />
                                                )}
                                            />
                                             <FormInput
                                                className="md:w-1/5 md:mx-2"
                                                label="Type"
                                                name={`type${index}`}
                                                form={form}
                                                errors={errors}
                                                validation={{
                                                    required: { value: true, message: 'You must select a type' },
                                                }}
                                                render={({ name, className, ref, label }) => (

                                                    <select ref={ref} name={name} className={className} placeholder={label} >
                                                         <option value='slider' name='slider'>slider</option>
                                                         <option value='single choice' name='single choice'>single choice</option>
                                                         <option value='ranking' name='ranking'>ranking</option>
                                                    </select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                ))}
                                {numQuestions > 1 && (
                                    <div className="text-xs text-right">
                                        <button
                                            type="button"
                                            className="btn btn-sm bg-red-500 px-3 py-1 text-white"
                                            onClick={() => setNumQuestions(numQuestions - 1)}
                                        >
                                            Remove question
                                        </button>
                                    </div>
                                )}  

                            </div>
                        </div>

                        <div className="mt-2">
                            <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-20">
                                Submit
                            </button>
                        </div>
                    </fieldset>
                </form>
            </Loading>

    );
}
export default withRouter(AdvEditQuestions);