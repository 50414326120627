import React from 'react';

export default function LogoMin({ className } = { className: '' }) {
    return (
        <svg viewBox="0 0 79.481 10.383" className={className}>
            <g transform="translate(-83.48 -12.951)">
                <path fill="#505e82" d="m96.294 14.554h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m99.115 14.554h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m100.49 14.554h1.4499v-1.4499h-1.4499z"/>
                <path fill="#ffffff" d="m104.76 14.554h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m96.294 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m99.115 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m101.94 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m104.76 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m104.76 17.43h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m99.115 20.306h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m101.94 20.306h-1.4499v-1.4499h1.4499z"/>
                <path fill="#9aa1bc" d="m104.76 20.306h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m101.94 23.182h-1.4499v-1.4499h1.4499z"/>
                <path fill="#505e82" d="m104.76 23.182h-1.4499v-1.4499h1.4499z"/>
                <path fill="#ffffff" d="m93.394 14.554v-1.4499h-9.9141v10.078h2.6162s0.06315-2.4137 1.3956-3.8248c1.2072-1.2788 3.1933-1.9276 5.9023-1.9276v-1.4499c-3.1383 0-5.4857 0.80892-6.9755 2.4042-0.90699 0.97084-1.3099 2.0443-1.4866 2.8688v-6.6989z"/>
               </g>
        </svg>
    );
}