import React, { useState, useEffect } from 'react';
import { pools } from './assetList';
import { ParaSwapper } from './paraswap';
import pendingIcon from '../../../images/Pending_Icon.png';
import tickIcon from '../../../images/tick.png';
import { mintIdle } from './IdleFunctions';

export default function TransactionButton(props) {

    const [txFilled, setTxFilled] = useState(null);
    const [isExecuting, setIsExecuting] = useState(false);
    const [executed, setExecuted] = useState(false);

    const { fromDecimals, fromName, toName, transaction, amount, address, poolName, disabled } = props;
    localStorage.setItem( ('isFilled'+ fromName) , 0 );


    useEffect(() => {
        if (localStorage.getItem(('isFilled' + fromName)) == 1)
        {
            setTxFilled(true);
            localStorage.setItem( ('isFilled'+ fromName) , null);
        }

    }, [disabled])
 
    const paraswapTransaction = async () => {
        const paraswapInstance = new ParaSwapper(1);
        await paraswapInstance.swapHardhat(amount, fromName, toName , address, pools[poolName])
        .then(function (receipt) {
            setExecuted(true);
            setIsExecuting(false)   
            setTxFilled(true);  
            localStorage.setItem( ('isFilled'+ fromName) , 1 );
        });
    }

    const makeTransaction = async () => {
        setIsExecuting(true);
        setTxFilled(false);
        
        switch(transaction){
            case 'paraswap':
            paraswapTransaction();
            break;
            case 'idleMint':
            await mintIdle(address, amount * (10**fromDecimals));
            setExecuted(true);
            setIsExecuting(false)   
            setTxFilled(true);  
            break;   
        }
   
    }

    return (
        <>
        <td className="order-td">
        {isExecuting 
        ?
        <button type="button" onClick={makeTransaction} className="btn text-sm border-gray-400 cursor-wait dark:text-gray-400 bg-darkblue-200 hover:bg-lightblue-200 w-9/12 text-center mb-2" disabled={disabled}>
         <b>PENDING</b>
        </button>
        :executed 
            ?<button type="button" onClick={makeTransaction} className="btn text-sm text-lightblue border-lightblue bg-darkblue-200 hover:bg-lightblue-200 w-9/12 text-center mb-2 cursor-not-allowed" disabled={disabled}>
            <b>EXECUTED</b>
            </button>
            :<button type="button" onClick={makeTransaction} className="btn text-sm text-white bg-lightblue-100 hover:bg-lightblue-200 w-9/12 text-center mb-2" disabled={disabled}>
            <b>EXECUTE</b>
            </button>
        }
        
        </td>
        {txFilled === true 
            ? <td className="order-td"> <td className="order-td"><img src={tickIcon} alt="ready" height="30" width="30"/></td> </td>  
            : txFilled === false
                ?<td className="order-td"> <img className="rotate" src={pendingIcon} alt="wait" height="30" width="30"/> </td>
                : <span></span>}
        </>
    );
}