export const colors = [ 
    '#ff321f', 
    '#fff000', 
    '#66a958', 
    '#b1cb99', 
    '#0022ff',
    '#4f61af', 
    '#ef7500', 
    '#8f4c2f', 
    '#a53c95', 
    '#4de48f', 
    '#c54d37', 
    '#9faa58', 
    '#94655a', 
    '#447601', 
    '#7916e5', 
    '#d148d0', 
    '#feac81', 
    '#ff2fff', 
    '#ff1000',
    '#223311', 
    '#66222a',
    '#fd3300'
]

