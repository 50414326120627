import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FormInput } from '../../utils/FormInput';
import { UserContext } from '../../utils/UserProvider';
import { http } from '../../utils/utils';
import Web3 from 'web3';

export default function EthAddess() {
    const userContext = useContext(UserContext);
    const ethAddress = userContext.me?.verification?.ethAddress || '';

    let web3;
    if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
    }
    
    const form = useForm({defaultValues: { ethAddress} });
    const { formState, handleSubmit, errors } = form;

    const onSubmit = async (formData) => {
        try {
            const address = formData.ethAddress;

            if(web3.utils.isAddress(address))
            {   
                // send data
                const me = await http({
                    method: 'POST',
                    url: '/verification/eth-address',
                    form: {
                        ethAddress : address
                    }
                });
                userContext.update(me);
                toast.success('Information updated successfully');
            }
            else
            {
                toast.error('Please insert a valid Ethereum Address');
            }
        } catch (e) {
            console.error(e);
            toast.error(e?.reason || `${e}`);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={formState.isSubmitting}>
                <div className="mb-8">
                    <h2 className="h4 mb-4">Ethereum Address</h2>
                </div>

                <div className="flex flex-wrap -m-2">
                    <FormInput
                        className="w-full md:w-1/2 p-2"
                        label="Ethereum Adress"
                        name="ethAddress"
                        form={form}
                        errors={errors}
                        validation={{
                            required: { value: true, message: 'You must enter your Ethereum address' }
                        }}
                        render={({ name, className, ref, label }) => (
                            <input ref={ref} name={name} className={className} placeholder='0x0000000000000000000000' />
                        )}
                    />
                </div>

                <div className="flex flex-wrap mt-6">
                    <button type="submit" className="btn text-white bg-lightblue-100 hover:bg-lightblue-200 px-6">
                        Continue
                    </button>
                </div>
            </fieldset>
        </form>
    );
}