import React from 'react';

function HeroHome() {

    let arr = [];
    let elem;
    for(let i = 5; i < 100; i+= 10)
    {
      const left = (i).toString() + "%";
      for (let j = 0; j < 100; j += 10)
      {
        const top = (j).toString() + "%"
        const delay = Math.abs(50-i)*20
        const animation = ((delay/1000) + 3).toString()+'s';
        arr.push({'marginTop': top, 'marginLeft': left, 'delay': delay, 'animation': animation});
      }
    }

    const col = arr.map(el => 
      <div style={{ animationDuration: el.animation}} className="animate-flicker">
      <div style={{ marginTop: el.marginTop, marginLeft: el.marginLeft}} className="square bg-blue-300 h-4 w-4 hidden absolute top-0 left-0 z-10 xl:inline-block" data-aos="zoom-in" data-aos-delay={el.delay} data-aos-anchor="[data-aos-id-squares]"></div>
      </div>
      );

  return (
    <section className="more-mb mt-5 z-0 relative data-aos-id-squares">
      <div className="fixed w-36 h-36 z-h top-2/3 right-0">
        <a href="#contact-form"><img 
          src={require("../images/PopUpElement.png")}
          onMouseOver={e => (e.currentTarget.src = require("../images/PopUpElement_Hover.png"))}
          onMouseLeave={e => (e.currentTarget.src = require("../images/PopUpElement.png"))}
          onClick=""
          className="cursor-pointer hidden md:block lg:block"
        /></a>
      </div>
      {col}
      <div className="max-w-6xl mx-auto sm:px-6 more-mb">
        <div className="pb-10 md:pt-40 md:pb-16">

          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

            {/* Content */}
            <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>
            <div className="md:col-span-8 lg:col-span-8 mb-8 md:mb-0 text-center md:text-left max-w-6xl">
              <h1 className="h1 lg:text-6xl mb-4 font-extrabold text-center" data-aos="fade-down">
                Your BRIDGE<br></br> to DeFi yields
              </h1>
              <p className="text-l text-gray-600 dark:text-gray-400 text-center resText" data-aos="fade-down" data-aos-delay="150">
                <b>BRIDGE</b> is the gateway to connect Traditional Finance to Decentralized Finance (DeFi). It designs diversified strategies, optimized on-chain solutions and smart contracts allowing institutions to obtain structured exposure to DeFi crypto-activities</p>
            </div>
            <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>

            </div>
        </div>
        
      </div>

      <div className="col-md-10 mt-5 lg:mx-20 md:mx-20">
        <div className="max-w-75r mx-auto px-4 sm:px-6">
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

            {/* 1st item */}
            <div className="relative flex flex-row items-center" >
              <img className=" w-12" src={require('../images/tick.png')} width="180" height="180" alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
              <p className="text-lg text-gray-400 ml-2" data-aos="fade-right" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">Strategic and operational<br></br> crypto fund advisory</p>
            </div>
                

            {/* 2nd item */}
            <div className="relative flex flex-row items-center">
              <img className=" w-12" src={require('../images/tick.png')} width="180" height="180" alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
              <p className="text-lg text-gray-400 ml-2" data-aos="fade-right" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">DeFi strategies tokenization and automation</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-row items-center" >
              <img className=" w-12" src={require('../images/tick.png')} width="180" height="180" alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
              <p className="text-lg text-gray-400 ml-2" data-aos="fade-right" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">Non custodial platform to provide on-chain access to strategies</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;