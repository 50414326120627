import React from 'react';

function HeroAbout() {

    let arr = [];
    let elem;
    for(let i = 5; i < 100; i+= 10)
    {
      const left = (i).toString() + "%";
      for (let j = 0; j < 100; j += 10)
      {
        const top = (j).toString() + "%"
        const delay = Math.abs(50-i)*20
        const animation = ((delay/1000) + 3).toString()+'s';
        arr.push({'marginTop': top, 'marginLeft': left, 'delay': delay, 'animation': animation});
      }
    }

  return (
    <section className="more-mb mt-5 z-0 relative work-sans">
      <div className="max-w-6xl mx-auto md:px-6 more-mb">
        <div className="md:pt-32 md:pb-16">

          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

            {/* Content */}
            <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>
            <div className="md:col-span-8 lg:col-span-8 mb-8 md:mb-0 text-center md:text-left max-w-6xl">
              <h2 className="h2 text-2-5xl mb-4 font-bold text-center" data-aos="fade-down">
                A team with<br></br> a mission
              </h2>
              <p className="md:text-xl text-gray-600 dark:text-gray-400 text-center resText60" data-aos="fade-down" data-aos-delay="150">
                Building a <b>BRIDGE</b> between decentralized finance investment opportunities and traditional financial technologies
              </p>
            </div>
            <div className="md:col-span-2 lg:col-span-2 mb-8 md:mb-0 text-center md:text-left"></div>

            </div>
        </div>
        
      </div>

      <div className="col-md-10 mt-5 md:mx-20 lg:mx-20">
        <div className="max-w-75r mx-auto px-2 md:px-6">
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

            {/* 1st item */}
            <div className="relative flex flex-row items-center" >
              <img className="md:w-14 lg:w-16 w-16" src={require('../images/doers.svg')} width="70" height="70" alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
              <div className="ml-5">
                <p className="text-medium md:mb-2 lg:mb-2 font-bold" data-aos="fade-down">
                    WE ARE DOERS
                </p>
                <p className="text-xs font-bold text-gray-400" data-aos="fade-right" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
                    <b>BRIDGE</b> team is composed by passionate people with different backgrounds, but shared values and goals. We are fast in getting things done
                </p>
              </div>
            </div>
                

            {/* 2nd item */}
            <div className="relative flex flex-row items-center" >
              <img className="md:w-14 lg:w-16 w-16" src={require('../images/we_try_new.svg')} width="70" height="70" alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
              <div className="ml-5">
                <p className="text-medium md:mb-2 lg:mb-2 font-bold" data-aos="fade-down">
                    WE TRY NEW THINGS
                </p>
                <p className="text-xs font-bold text-gray-400" data-aos="fade-right" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
                    We are trying to revolutionize finance and financial services technology, focusing on building innovative products and services
                </p>
              </div>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-row items-center" >
              <img className="md:w-14 lg:w-16 w-16" src={require('../images/believers.svg')} width="80" height="80" alt="Invest" data-aos="fade-right" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]"/>
              <div className="ml-5">
              <p className="text-medium md:mb-2 lg:mb-2 font-bold" data-aos="fade-down">
                    WE ARE BELIEVERS
                </p>
                <p className="text-xs font-bold text-gray-400" data-aos="fade-right" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
                DeFi will revolutionize fintech sector as Internet has done with communications. We want to facilitate the transition to the new financial system. The future is bright
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroAbout;