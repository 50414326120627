import React, { useState, useEffect } from 'react';
import { withdrawAave,
         withdrawIdle,
         withdrawCurvea3CRVgauge,
         withdrawCurvea3CRV, 
         withdrawCurveSteCRVgauge, 
         withdrawCurveSteCRV, 
         withdrawYearnUSDC,
         withdrawYearnDAI
} from './blockchainFunctions';
import { pools, aUSDC ,USDC} from './assetList';
import { ParaSwapper } from './paraswap';
import pendingIcon from '../../../images/Pending_Icon.png';
import tickIcon from '../../../images/tick.png';

export default function TransactionButton(props) {

    const [txFilled, setTxFilled] = useState(null);
    const [isExecuting, setIsExecuting] = useState(false);

    const { fromDecimals, fromName, toName, transaction, amount, address, poolName, disabled } = props;

    useEffect(() => {

        if (localStorage.getItem(('isFilled' + fromName)) == 1)
        {
            setTxFilled(true);
            localStorage.setItem( ('isFilled'+ fromName) , null);
        }

    }, [disabled])
 

    const withdrawAaveToken = async () => {
        await withdrawAave(address, USDC, (amount * 10**fromDecimals).toString());
    }
    const withdrawIdleToken = async () => {
        await withdrawIdle(address, ( amount * 10**fromDecimals).toString());
    }
    const withdrawCurve3CRVGaugeToken = async () => {
        await withdrawCurvea3CRVgauge(address, (amount  * 10**fromDecimals).toString());
    }
    const withdrawYearnUSDCToken = async () => {
        await withdrawYearnUSDC(address, (amount  * 10**fromDecimals).toString());
    }
    const withdrawYearnDAIToken = async () => {
        await withdrawYearnDAI(address, (amount  * 10**fromDecimals).toString());
    }
     const withdrawCurve3CRVToken = async () => {
        await withdrawCurvea3CRV(address, (amount * 10**fromDecimals).toString());
    }
    const withdrawCurveSteCRVGaugeToken = async () => {
        await withdrawCurveSteCRVgauge(address, (amount * 10**fromDecimals).toString());
    }
     const withdrawCurveSteCRVToken = async () => {
        await withdrawCurveSteCRV(address, (amount * 10**fromDecimals).toString());
    }  
    const paraswapTransaction = async () => {
        const paraswapInstance = new ParaSwapper(1);
        await paraswapInstance.swapHardhat(amount, fromName, toName , address, pools[poolName])
        .then(function (receipt) {
            props.balances();       
        });
    }

    const makeTransaction = async () => {
        setIsExecuting(true);
        setTxFilled(false);

        switch(transaction){
            case 'paraswap':
            paraswapTransaction();
            break;
            case 'aave':
            await withdrawAaveToken();
            break;
            case 'idle':
            await withdrawIdleToken();
            break;
            case 'a3CRVgauge':
            await withdrawCurve3CRVGaugeToken();
            break;
            case 'a3CRV':
            await withdrawCurve3CRVToken();
            break;
            case 'steCRVgauge':
            await withdrawCurveSteCRVGaugeToken();
            break;
            case 'steCRV':
            await withdrawCurveSteCRVToken();
            break;
            case 'yvUSDC':
            await withdrawYearnUSDCToken();
            break;
            case 'yvDAI':
            await withdrawYearnDAIToken();
            break;
        }
        if(transaction != 'paraswap')
        {
            props.balances();
        }
        localStorage.setItem( ('isFilled'+ fromName) , 1 );
    }

    return (
        <>
        <td className="order-td">
        {isExecuting 
        ?
        <button type="button" onClick={makeTransaction} className="btn text-sm border-gray-400 cursor-wait dark:text-gray-400 bg-darkblue-200 hover:bg-lightblue-200 w-9/12 text-center mb-2" disabled={disabled}>
         <b>PENDING</b>
        </button>
        :disabled 
            ?<button type="button" onClick={makeTransaction} className="btn text-sm text-lightblue border-lightblue bg-darkblue-200 hover:bg-lightblue-200 w-9/12 text-center mb-2 cursor-not-allowed" disabled={disabled}>
            <b>EXECUTED</b>
            </button>
            :<button type="button" onClick={makeTransaction} className="btn text-sm text-white bg-lightblue-100 hover:bg-lightblue-200 w-9/12 text-center mb-2" disabled={disabled}>
            <b>EXECUTE</b>
            </button>
        }
        
        </td>
        {txFilled === true 
            ? <td className="order-td"> <td className="order-td"><img src={tickIcon} alt="ready" height="30" width="30"/></td> </td>  
            : txFilled === false
                ?<td className="order-td"> <img className="rotate" src={pendingIcon} alt="wait" height="30" width="30"/> </td>
                : <span></span>}
        </>
    );
}