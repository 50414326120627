import React from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../utils/FormInput';
import toast from 'react-hot-toast';
import { http } from '../../utils/utils';


export default function SelectRole(props) {

    const form = useForm({ defaultValues: { active: true } });
    const { formState, handleSubmit, errors, getValues, reset, watch } = form;

    const onSubmit = async (formData) => {

        formData.isAdmin = true;

        try {

            await http({
                method: 'PUT',
                url: `/users/${props.userId}/assign-role`,
                form: formData
            });
        
        toast.success('Role successfully assigned to user');
        } catch (e) {
            console.error(e);
            toast.error(e?.reason?.error || `${JSON.stringify(e.reason.message)}`);
        }
    };

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="block">
                <label className="text-white uppercase font-bold  text-sm">Admin Role</label>
               <button type="submit"
                    className={`cursor-pointer text-xs pl-4 text-right font-bold text-lightblue-100 hover:text-lightblue-200 font-bold ml-4}`}
                >
                    Update
                </button> 
            </div>
            <FormInput
                className="w-full mb-0-i text-white"
                name='roleId'
                form={form}
                errors={errors}
                render={({ name, className, ref }) => (
                    <select ref={ref} name={name} className={className}>
                        <option default>-</option>
                        {props.roles.map(r => {
                            return <option value={r._id} name={r.name} selected={r._id == props.roleId}>{r.name}</option>;
                        })}
                    </select>
                )}
            />
        </form> 
        </>
    )
}