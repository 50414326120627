import React from "react";
import { ResponsiveLine } from '@nivo/line';

export const PoolChart = ({ myData, skip }) => {

    const data = myData.map(e => e.data).flat();
    
    const yAxis = data.map(e => e.y)
    let xAxis = data.map(e => e.x)
    xAxis = [...new Set(xAxis)];

    const min = Math.min(... yAxis)
    const max = Math.max(... yAxis)

    return (
    <ResponsiveLine
        data={myData}
        margin={{ top: 50, right: 110, bottom: 80, left: 36 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: min - Math.abs(min) * 0.7, max: max + max * 0.2 }}
        yFormat=">-.2f"
        enableSlices='x'
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            tickValues: xAxis.filter((v, index) => index % skip === 0),
            legend: '',
            tickCounters: 2,
            legendOffset: 50,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 2,
            tickRotation: 0,
            legend: '',
            format: value => value + ' %',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={1}
        colors={{ datum: 'color' }}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        theme={{
            textColor: '#fff',
            fontSize: '13px',
            axis: {
                domain: {
                    line: {
                        stroke: '#fff',
                        strokeWidth: 1,
                    }
                },
            },
            grid: {
                line: {
                    stroke: '#203a59',
                    strokeWidth: 1,
                    strokeDasharray: '1 1',
                }
            }
        }}
        sliceTooltip={(function (elem) {
            const points = elem.slice.points.map(y => { return { value: y.data.yFormatted, color: y.borderColor, id: y.serieId } })
            const date = elem.slice.points[0].data.x

            const pointsBox = points.map(p => {
                return (
                    <>
                      <div className="flex flex-row">
                        <div className="">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 700 500">
                                    <rect x="10" y="10" rx="0" ry="0" width="300" height="300" stroke-width="1" stroke={p.color} fill={p.color} />
                                </svg>
                            </div>
                        </div>
                        <div className="mr-5">
                            {p.id}
                        </div>
                        <div className="right-0">
                            {p.value}%
                        </div>
                      </div>
                    </>
                )
            })
        return (
            <div className="hidden md:block"
                style={{
                    padding: 12,
                    color: '#ffffff',
                    background: '#203b5a',
                }}
            >
                {date}
                {pointsBox}
            </div>
        )})}
        enableCrosshair={false}
    />);
}